
import { userStore } from "@/store/user";
import { defineComponent } from "vue";
export default defineComponent({
  name: "User",
  setup() {
    const currentUser = userStore.currentUser;
    return { currentUser };
  },
});
