import { Ref, ref } from "vue";
import { apiCompany } from "@/lib/api_gin";

const tagList: Ref<{ id: number; name: string }[]> = ref([]);
const categoryList: Ref<{ id: number; name: string }[]> = ref([]);
const init = async (ginToken: string) => {
  const tagListRes = await apiCompany.getTagList(ginToken);
  const categoryListRes = await apiCompany.getCategoryList(ginToken);
  tagList.value = tagListRes
    .getTagsList()
    .map((e) => ({ id: e.getId(), name: e.getName() }))
    .sort((a, b) => a.id - b.id);

  categoryList.value = categoryListRes
    .getCategorysList()
    .map((e) => ({ id: e.getId(), name: e.getName() }))
    .sort((a, b) => a.id - b.id);
};

export const starStore = {
  init,
  tagList,
  categoryList,
};
