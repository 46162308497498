
import { apiCompany } from "@/lib/api_gin";
import { BaseUser, AgentUser } from "@/models/gin";
import { userStore } from "@/store/user";
import {
  defineComponent,
  reactive,
  Ref,
  ref,
  onMounted,
  toRefs,
  computed,
} from "vue";
import Icon, { SearchOutlined, EyeFilled } from "@ant-design/icons-vue";
import UserSelector from "@/components/UserSelector.vue";
import { Toast } from "@/lib/toast";
import Empty from "@/components/Empty.vue";
import Loader from "@/components/Loader.vue";
export default defineComponent({
  name: "ViewAdminList",
  components: {
    UserSelector,
    SearchOutlined,
    EyeFilled,
    Empty,
    Loader,
  },
  setup() {
    const state = reactive({
      search: "",
      status: true,
      isLoading: false,
    });
    const agentList: Ref<AgentUser[]> = ref([]);
    const isSupervisor = computed(() => {
      const role = userStore.currentUser.value.role;
      return role.isCompany() || role.isAdmin();
    });
    const isEditable = computed(() => {
      return (
        userStore.currentUser.value.role.isCompany() ||
        userStore.currentUser.value.role.isAdmin()
      );
    });
    const isMe = (ginId: number): boolean => {
      return ginId === userStore.currentUser.value.ginId;
    };

    const getAgents = async () => {
      state.isLoading = true;
      const res = await apiCompany.getStaffList(
        {
          name: state.search,
          role: "agent",
          status: state.status,
        },
        userStore.ginToken.value
      );
      agentList.value = res
        .getDataList()
        .map((e) => new AgentUser(e))
        .sort((a, b) => Number(isMe(b.ginId)) - Number(isMe(a.ginId)));

      state.isLoading = false;
    };

    const subMemberList: Ref<BaseUser[]> = ref([]);
    const getSubMemberList = async () => {
      const accessToken = await userStore.getAccessToken();
      if (accessToken) {
        const res = await window.dog.pan.api.OtherMember(accessToken);
        const excludes: string[] = await apiCompany
          .getStaffList({ status: true }, userStore.ginToken.value)
          .then((r) => r.getDataList().map((e) => e.getObjectId()));
        subMemberList.value = res
          .filter((e) => !e.getIsOwner() && !excludes.includes(e.getObjectId()))
          .map((e) => new BaseUser(e));
      }
    };
    const onSelect = async (payload: {
      item: BaseUser;
      close: () => void;
      toDone: () => void;
    }) => {
      try {
        const res = await apiCompany.setSubMemberAs(
          {
            objectId: payload.item.objectId,
            role: "agent",
          },
          userStore.ginToken.value
        );
        subMemberList.value = subMemberList.value.filter(
          (e) => e.objectId !== payload.item.objectId
        );
        payload.toDone();
      } catch (error) {
        Toast.bugOccur();
      }
    };
    onMounted(() => {
      getAgents();
      getSubMemberList();
    });
    return {
      ...toRefs(state),
      agentList,
      getAgents,
      onSelect,
      subMemberList,
      isMe,
      isEditable,
      isSupervisor,
    };
  },
});
