import { timestampToDayjs } from "@/lib/utils";
import {
  BloodType,
  BoundaryType,
  RegisterStatus,
} from "@lctech-tw/gin-proto/dist/js/star/atom/enums_pb";
import {
  StarText,
  StarImage,
  StarRecord,
  StarProfileData,
  StarIntro,
  ModelCardInfo,
  StarPrivateData,
  IdCardInfo,
  GCSData,
  RegisterDetail,
} from "@lctech-tw/gin-proto/dist/js/star/atom/model_pb";
import {
  StarDetailRes,
  StarRegisterDetailRes,
} from "@lctech-tw/gin-proto/dist/js/star/gin/gin_pb";
import { MyRegisterProfileRes } from "@lctech-tw/gin-proto/dist/js/star/stars/stars_pb";
import dayjs, { Dayjs } from "dayjs";
class SNSList {
  snsUrls: SNS[] = [];
  add(url?: string) {
    this.snsUrls.push(new SNS(url || ""));
  }
  remove(sns: SNS) {
    const index = this.snsUrls.findIndex((e) => e === sns);
    this.snsUrls.splice(index, 1);
  }
}
class SNS {
  id: number;
  url: string;
  constructor(url: string) {
    this.url = url;
    this.id = Math.floor(Math.random() * 100000);
  }
}
class Image {
  url = "";
  path = "";
  constructor(url?: string, path?: string) {
    if (url) {
      this.url = url;
    }
    if (path) {
      this.path = path;
    }
  }
}
class RegisterForm {
  starObjectId = "";

  //   textMetadata? = StarText;
  phone = "";
  realName = ""; //*真實姓名
  marriage = false; //*婚姻
  address = ""; //*地址
  line = ""; //*line帳號
  stageName = ""; //*藝名
  birthday: Dayjs | undefined = dayjs(); //*生日 MM/DD
  bloodType = BloodType.BLOOD_TYPE_EMPTY; // 血型
  bust = ""; //*胸圍
  waist: number | null = null; //*腰圍
  hips: number | null = null; //*臀圍
  height: number | null = null; //*身高
  weight: number | null = null; //*體重
  earPierce = false; //*耳洞
  shoeSize = ""; //*鞋號尺寸
  tattoo = ""; //*有無刺青
  instagramList: SNSList = new SNSList();
  facebookList: SNSList = new SNSList();
  twitterList: SNSList = new SNSList();
  tiktokList: SNSList = new SNSList();
  jvid = ""; //jvid網址
  ladyIntro: {
    myType: string;
    hashTag: string;
  } = {
    myType: "",
    hashTag: "",
  };
  hobby = "";
  talent = "";
  occupation = ""; //*職業
  education = ""; // 最高學歷
  photoBoundary = BoundaryType.BT_NORMAL; //*拍攝尺度（平面）
  videoBoundary = BoundaryType.BT_NORMAL; //*拍攝尺度（動態）

  //   imageMetadata?: StarImage;
  logo = new Image(); //*手拿logo照
  modelCard: {
    //*生活自拍照
    card1: Image;
    card2: Image;
    card3: Image;
    card4: Image;
  } = {
    card1: new Image(),
    card2: new Image(),
    card3: new Image(),
    card4: new Image(),
  };
  banner = new Image(); //*banner照片

  //   recordMetadata?: StarRecord;
  q1 = "";
  q2 = "";
  q3 = "";
  q4 = "";
  q5 = "";
  q6 = "";
  q7 = "";
  q8 = "";
  q9 = "";
  q10 = "";
  //   1. 是什麼原因讓妳想成為JKF女郎？
  //   2. 加入JKF對妳來說加分的條件是什麼？
  //   3. 最想參加JKF什麼類型的活動？為什麼？
  //   4. 覺得自己是什麼樣的女生？有反差的一面嗎？
  //   5. 覺得自己最性感的時刻是什麼時候？為什麼？
  //   6. 第一次戀愛經驗是幾歲？最讓妳難忘的回憶是什麼？
  //   7. 目前有正職或是其他斜槓嘛？
  //   8. 日常休假興趣或其他專長是什麼？
  //   9. 除了自己以外，最欣賞的JKF女郎是誰？原因？
  //   10. 如果要挑戰大尺度拍攝，你最想嘗試哪一種造型或主題？
  memo = "";
  tagList: number[] = [];
  category = 0;

  idCardFront = new Image();
  idCardBack = new Image();
  bank = new Image();
  constructor(
    res?: MyRegisterProfileRes | StarRegisterDetailRes | StarDetailRes
  ) {
    const info = res?.getData();
    if (info) {
      const textMetadata =
        info.getProfile()?.getTextMetadata() || new StarText();

      const imageMetadata =
        info.getProfile()?.getImageMetadata() || new StarImage();

      const recordMetadata =
        info.getProfile()?.getRecordMetadata() || new StarRecord();

      this.memo = info.getProfile()?.getMemo() || "";
      this.tagList = info.getProfile()?.getTagList() || [];
      this.category = info.getProfile()?.getCategory() || 0;

      const privateData = info.getPrivateData() || new StarPrivateData();
      this.setData(textMetadata, imageMetadata, recordMetadata, privateData);
    }
  }
  setData(
    textMetadata: StarText,
    imageMetadata: StarImage,
    recordMetadata: StarRecord,
    privateData?: StarPrivateData
  ): void {
    this.phone = textMetadata.getPhone();
    this.realName = textMetadata.getRealName();
    this.marriage = textMetadata.getMarriage();
    this.address = textMetadata.getAddress();
    this.line = textMetadata.getLine();
    this.stageName = textMetadata.getStageName();
    this.birthday =
      timestampToDayjs(textMetadata.getBirthday())?.year() === 1
        ? dayjs()
        : timestampToDayjs(textMetadata.getBirthday());
    this.bloodType = textMetadata.getBloodType(); // 血型
    this.bust = textMetadata.getBust();
    this.waist = textMetadata.getWaist() || null;
    this.hips = textMetadata.getHips() || null;
    this.height = textMetadata.getHeight() || null;
    this.weight = textMetadata.getWeight() || null;
    this.earPierce = textMetadata.getEarPierce();
    this.shoeSize = textMetadata.getShoeSize();
    this.tattoo = textMetadata.getTattoo();
    this.instagramList.snsUrls = textMetadata
      .getInstagramList()
      .map((e) => new SNS(e));
    this.facebookList.snsUrls = textMetadata
      .getFacebookList()
      .map((e) => new SNS(e));
    this.twitterList.snsUrls = textMetadata
      .getTwitterList()
      .map((e) => new SNS(e));
    this.tiktokList.snsUrls = textMetadata
      .getTiktokList()
      .map((e) => new SNS(e));
    // [
    //   this.instagramList,
    //   this.facebookList,
    //   this.twitterList,
    //   this.tiktokList,
    // ].map((e) => {
    //   if (e.snsUrls.length === 0) {
    //     e.add();
    //   }
    // });
    this.jvid = textMetadata.getJvid();
    const ladyIntro = textMetadata.getLadyIntro() || new StarIntro();
    this.ladyIntro = {
      myType: ladyIntro.getMyType(),
      hashTag: ladyIntro.getHashTag(),
    };
    this.hobby = ladyIntro.getLikeList()[0] || "";
    this.talent = ladyIntro.getSkillList()[0] || "";
    this.occupation = textMetadata.getOccupation();
    this.education = textMetadata.getEducation();
    this.photoBoundary = textMetadata.getPhotoBoundary();
    this.videoBoundary = textMetadata.getVideoBoundary();

    this.logo = new Image(
      imageMetadata.getLogo()?.getPublicUrl(),
      imageMetadata.getLogo()?.getGcsPath()
    );
    this.modelCard.card1 = new Image(
      imageMetadata.getModelCard()?.getCard1()?.getPublicUrl(),
      imageMetadata.getModelCard()?.getCard1()?.getGcsPath()
    );
    this.modelCard.card2 = new Image(
      imageMetadata.getModelCard()?.getCard2()?.getPublicUrl(),
      imageMetadata.getModelCard()?.getCard2()?.getGcsPath()
    );
    this.modelCard.card3 = new Image(
      imageMetadata.getModelCard()?.getCard3()?.getPublicUrl(),
      imageMetadata.getModelCard()?.getCard3()?.getGcsPath()
    );
    this.modelCard.card4 = new Image(
      imageMetadata.getModelCard()?.getCard4()?.getPublicUrl(),
      imageMetadata.getModelCard()?.getCard4()?.getGcsPath()
    );
    this.banner = new Image(
      imageMetadata.getBanner()?.getPublicUrl(),
      imageMetadata.getBanner()?.getGcsPath()
    );

    this.q1 = recordMetadata.getQ1();
    this.q2 = recordMetadata.getQ2();
    this.q3 = recordMetadata.getQ3();
    this.q4 = recordMetadata.getQ4();
    this.q5 = recordMetadata.getQ5();
    this.q6 = recordMetadata.getQ6();
    this.q7 = recordMetadata.getQ7();
    this.q8 = recordMetadata.getQ8();
    this.q9 = recordMetadata.getQ9();
    this.q10 = recordMetadata.getQ10();

    if (privateData) {
      this.idCardFront = new Image(
        privateData.getIdentityCard()?.getFront()?.getPublicUrl(),
        privateData.getIdentityCard()?.getFront()?.getGcsPath()
      );
      this.idCardBack = new Image(
        privateData.getIdentityCard()?.getBack()?.getPublicUrl(),
        privateData.getIdentityCard()?.getBack()?.getGcsPath()
      );
      this.bank = new Image(
        privateData.getBankAccount()?.getPublicUrl(),
        privateData.getBankAccount()?.getGcsPath()
      );
    }
  }

  toStarProfileData(): StarProfileData {
    const profile = new StarProfileData();
    // textMetadata
    const textMetadata = new StarText();
    textMetadata.setPhone(this.phone);
    textMetadata.setStageName(this.stageName);
    textMetadata.setRealName(this.realName);
    textMetadata.setAddress(this.address);
    textMetadata.setBirthday(this.birthday?.toTimestamp());
    textMetadata.setBloodType(this.bloodType);
    textMetadata.setBust(this.bust);
    textMetadata.setWaist(this.waist || 0);
    textMetadata.setHips(this.hips || 0);
    textMetadata.setHeight(this.height || 0);
    textMetadata.setWeight(this.weight || 0);
    textMetadata.setLine(this.line);
    textMetadata.setInstagramList(
      this.instagramList.snsUrls.filter((e) => e.url).map((e) => e.url)
    );
    textMetadata.setFacebookList(
      this.facebookList.snsUrls.filter((e) => e.url).map((e) => e.url)
    );
    textMetadata.setTwitterList(
      this.twitterList.snsUrls.filter((e) => e.url).map((e) => e.url)
    );
    textMetadata.setTiktokList(
      this.tiktokList.snsUrls.filter((e) => e.url).map((e) => e.url)
    );
    const ladyIntro = new StarIntro();
    ladyIntro.setMyType(this.ladyIntro.myType);
    ladyIntro.setHashTag(this.ladyIntro.hashTag);
    ladyIntro.setSkillList([this.talent]);
    ladyIntro.setLikeList([this.hobby]);
    textMetadata.setLadyIntro(ladyIntro);
    textMetadata.setJvid(this.jvid);
    textMetadata.setMarriage(this.marriage);
    textMetadata.setOccupation(this.occupation);
    textMetadata.setEducation(this.education);
    textMetadata.setEarPierce(this.earPierce);
    textMetadata.setTattoo(this.tattoo);
    textMetadata.setShoeSize(this.shoeSize);
    textMetadata.setPhotoBoundary(this.photoBoundary);
    textMetadata.setVideoBoundary(this.videoBoundary);
    profile.setTextMetadata(textMetadata);
    // // imageMetadata
    const imageMetadata = new StarImage();

    const bannerImage = new GCSData();
    bannerImage.setGcsPath(this.banner.path);
    imageMetadata.setBanner(bannerImage);

    const logoImage = new GCSData();
    logoImage.setGcsPath(this.logo.path);
    imageMetadata.setLogo(logoImage);

    const modelCard = new ModelCardInfo();
    const card1 = new GCSData();
    card1.setGcsPath(this.modelCard.card1.path);
    modelCard.setCard1(card1);
    const card2 = new GCSData();
    card2.setGcsPath(this.modelCard.card2.path);
    modelCard.setCard2(card2);
    const card3 = new GCSData();
    card3.setGcsPath(this.modelCard.card3.path);
    modelCard.setCard3(card3);
    const card4 = new GCSData();
    card4.setGcsPath(this.modelCard.card4.path);
    modelCard.setCard4(card4);
    imageMetadata.setModelCard(modelCard);

    profile.setImageMetadata(imageMetadata);
    // // recordMetadata
    const recordMetadata = new StarRecord();
    recordMetadata.setQ1(this.q1);
    recordMetadata.setQ2(this.q2);
    recordMetadata.setQ3(this.q3);
    recordMetadata.setQ4(this.q4);
    recordMetadata.setQ5(this.q5);
    recordMetadata.setQ6(this.q6);
    recordMetadata.setQ7(this.q7);
    recordMetadata.setQ8(this.q8);
    recordMetadata.setQ9(this.q9);
    recordMetadata.setQ10(this.q10);
    profile.setRecordMetadata(recordMetadata);
    // privateData
    const privateData = new StarPrivateData();
    const bankImage = new GCSData();
    bankImage.setGcsPath(this.bank.path);
    privateData.setBankAccount(bankImage);
    const idCardInfo = new IdCardInfo();
    const idCardFrontImage = new GCSData();
    idCardFrontImage.setGcsPath(this.idCardFront.path);
    idCardInfo.setFront(idCardFrontImage);
    const idCardBackImage = new GCSData();
    idCardBackImage.setGcsPath(this.idCardBack.path);
    idCardInfo.setBack(idCardBackImage);
    privateData.setIdentityCard(idCardInfo);
    // others
    profile.setRegisterStatus(RegisterStatus.WAIT_VERIFY);
    // profile.setMemo(this.memo);
    const values = Object.values(this.tagList);
    profile.setTagList(values);
    profile.setCategory(this.category);
    return profile;
  }

  toStarUpdatePrivateData(): StarPrivateData | undefined {
    if (
      Boolean(this.bank.path) ||
      Boolean(this.idCardFront.path) ||
      Boolean(this.idCardBack.path)
    ) {
      const privateData = new StarPrivateData();
      const bankImage = new GCSData();
      bankImage.setGcsPath(this.bank.path);
      privateData.setBankAccount(bankImage);
      const idCardInfo = new IdCardInfo();
      const idCardFrontImage = new GCSData();
      idCardFrontImage.setGcsPath(this.idCardFront.path);
      idCardInfo.setFront(idCardFrontImage);
      const idCardBackImage = new GCSData();
      idCardBackImage.setGcsPath(this.idCardBack.path);
      idCardInfo.setBack(idCardBackImage);
      privateData.setIdentityCard(idCardInfo);
      return privateData;
    } else {
      return undefined;
    }
  }

  toStarPrivateData(): StarPrivateData {
    const privateData = new StarPrivateData();
    const bankImage = new GCSData();
    bankImage.setGcsPath(this.bank.path);
    privateData.setBankAccount(bankImage);
    const idCardInfo = new IdCardInfo();
    const idCardFrontImage = new GCSData();
    idCardFrontImage.setGcsPath(this.idCardFront.path);
    idCardInfo.setFront(idCardFrontImage);
    const idCardBackImage = new GCSData();
    idCardBackImage.setGcsPath(this.idCardBack.path);
    idCardInfo.setBack(idCardBackImage);
    privateData.setIdentityCard(idCardInfo);
    return privateData;
  }

  setFakeImage(): void {
    const publicUrl =
      "https://storage.googleapis.com/dev-stars-public/7000126/Banner/faf760ee-984f-4496-a148-9a1bddac1241.jpeg";
    const publicPath =
      "7000126/Banner/faf760ee-984f-4496-a148-9a1bddac1241.jpeg";
    const publicImage = new Image(publicUrl, publicPath);
    const privateUrl =
      "https://resource.star.jkf.io/7000126/ModelCard/bac896da-3aae-4534-9946-bd5b49378fd5.png";
    const privatePath =
      "7000126/IdentityCard/63b973a1-16fe-4827-a968-423889a80ec7.png";
    const privateImage = new Image(privateUrl, privatePath);
    this.banner = publicImage;
    this.logo = publicImage;
    this.modelCard.card1 = publicImage;
    this.modelCard.card2 = publicImage;
    this.modelCard.card3 = publicImage;
    this.modelCard.card4 = publicImage;
    this.idCardFront = privateImage;
    this.idCardBack = privateImage;
    this.bank = privateImage;
  }
  setFakeData(): void {
    this.realName = "王小明";
    this.height = 155;
    this.weight = 42;
    this.bust = "36D";
    this.waist = 90;
    this.hips = 90;
    this.hobby = "興趣是看電視";
    this.talent = "跳繩";
    this.occupation = "待業";
    this.address = "中山路146號";
    this.line = "asd123";
    this.shoeSize = "US10號";
    this.tattoo = "上半身全甲";
    this.education = "大學";
    this.ladyIntro.myType = "大家好我是王小明";
    this.jvid = "https://jvid.com";
    this.facebookList.add("https://facebook.com");
    this.instagramList.add("https//ig.com");
    this.twitterList.add("https://twitter.com");
    this.tiktokList.add("https://tiktok.com");
    this.q1 = "第1題";
    this.q2 = "第2題";
    this.q3 = "第3題";
    this.q4 = "第4題";
    this.q5 = "第5題";
    this.q6 = "第6題";
    this.q7 = "第7題";
    this.q8 = "第8題";
    this.q9 = "第9題";
    this.q10 = "第10題";
    this.bloodType = BloodTypes.O;
    this.marriage = true;
    this.earPierce = true;
    this.photoBoundary =
      BoundaryTypes["大尺：全空遮、碼 (身體完全無任何衣物遮掩)"];
    this.videoBoundary =
      BoundaryTypes["大尺：全空遮、碼 (身體完全無任何衣物遮掩)"];
  }
}

const BloodTypes = {
  保密: BloodType.BLOOD_TYPE_EMPTY,
  不明: BloodType.BLOOD_TYPE_UNKNOWN,
  A: BloodType.BLOOD_TYPE_A,
  B: BloodType.BLOOD_TYPE_B,
  O: BloodType.BLOOD_TYPE_O,
  AB: BloodType.BLOOD_TYPE_AB,
};

const BoundaryTypes = {
  "一般：性感時裝 (會露肩、腿或胸腺)": BoundaryType.BT_NORMAL,
  "小尺：內睡衣/比基尼泳裝系列": BoundaryType.BT_SMALL,
  "中尺：上身不穿遮點、碼 (三點全遮)": BoundaryType.BT_MEDIUM,
  "大尺：全空遮、碼 (身體完全無任何衣物遮掩)": BoundaryType.BT_LARGE,
};

const humanCardName = new Map([
  ["card1", "生活自拍照1"],
  ["card2", "生活自拍照2"],
  ["card3", "生活自拍照3"],
  ["card4", "生活自拍照4"],
]);

export { RegisterForm, BloodTypes, BoundaryTypes, humanCardName };
