import { GinRole } from "@lctech-tw/gin-proto/dist/js/star/atom/enums_pb";
import {
  MemberInfo,
  RegisterData,
  RevenueData,
} from "@lctech-tw/gin-proto/dist/js/star/atom/model_pb";
import { MyCompanyRes } from "@lctech-tw/gin-proto/dist/js/star/member/member_pb";
import { PanAtomMemberFirst } from "@lctech-tw/f2e-doooooooog/lib/pan/proto";
import { RevenueRankingListRes } from "@lctech-tw/gin-proto/dist/js/star/gin/gin_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import dayjs from "dayjs";
import { SourceType } from "@lctech-tw/gin-proto/dist/js/public/reward/enums_pb";
import { humanRoles } from "./role";
enum Label {
  default = "",
  business = "業務性質",
  companyName = "公司名稱",
  managerName = "經紀名稱",
  phone = "聯絡電話",
  email = "公司信箱",
  uid = "UID",
  role = "身份權限",
}
enum UploadFileType {
  banner,
  modelcard,
  idcard,
  bank,
  logo,
}
class FormItem {
  label = Label.default;
  value = "";
  isRequired = false;
  isLocked = false;
  isEditableByCustomerService = false;
  constructor(label: Label) {
    const requiredParams = [
      Label.business,
      Label.companyName,
      Label.phone,
      Label.uid,
      Label.managerName,
      Label.role,
    ];
    const lockedParams = [
      Label.business,
      Label.companyName,
      Label.phone,
      Label.uid,
      Label.managerName,
      Label.role,
    ];
    const editableParams = [
      Label.companyName,
      Label.phone,
      Label.managerName,
      Label.role,
    ];
    this.label = label;
    this.isRequired = requiredParams.includes(this.label);
    this.isLocked = lockedParams.includes(this.label);
    this.isEditableByCustomerService = editableParams.includes(this.label);
  }
}
class CompanyForm {
  logo = "";
  business = new FormItem(Label.business);
  name = new FormItem(Label.companyName);
  phone = new FormItem(Label.phone);
  email = new FormItem(Label.email);
  constructor(res?: MyCompanyRes) {
    if (res) {
      const info = res.getData();
      this.logo = info?.getAvatarUrl() || "";
      this.business.value = "經紀公司";
      this.name.value = info?.getDisplayName() || "";
      this.phone.value = info?.getPhoneNumber() || "";
      this.email.value = info?.getEmail() || "";
    }
  }
  getReadItems(): FormItem[] {
    return [this.business, this.name, this.phone, this.email];
  }
  getInputItems(): FormItem[] {
    return [this.business, this.name, this.phone, this.email];
  }
}

class StaffForm {
  avatar = "";
  uid = new FormItem(Label.uid);
  name = new FormItem(Label.managerName);
  role = new FormItem(Label.role);
  status = false;
  constructor(res?: MemberInfo) {
    if (res) {
      this.uid.value = res.getUid().toString();
      this.name.value = res.getDisplayName();
      this.status = res.getStatus();
      this.avatar = res.getAvatarUrl();
      this.role.value = humanRoles.get(res.getRole()) || "";
    }
  }
  getReadItems(): FormItem[] {
    return [this.uid, this.name, this.role];
  }
  getInputItems(): FormItem[] {
    return [this.uid, this.name];
  }
}
class BaseUser {
  uid: number;
  objectId: string;
  name: string;
  avatar: string;
  phone: string;
  constructor(info?: PanAtomMemberFirst.Object) {
    if (info) {
      this.uid = info.getUid();
      this.objectId = info.getObjectId();
      this.name = info.getDisplayName();
      this.avatar = info.getAvatar();
      this.phone = info.getPhone();
    } else {
      this.uid = 0;
      this.objectId = "";
      this.name = "";
      this.avatar = "";
      this.phone = "";
    }
  }
  toCommonCardProps() {
    return {
      uid: this.uid,
      objectId: this.objectId,
      name: this.name,
      avatar: this.avatar,
    };
  }
  setAvatar(avatar: string): void {
    this.avatar = avatar;
  }
}
class GinUser {
  uid = 0;
  objectId = "";
  name = "";
  avatar = "";
  ginId = 0;
  status = false;
  role: "" | "company" | "admin" | "agent" | "star" = "";
  phone = "";
  constructor(info?: MemberInfo) {
    if (info) {
      this.uid = info.getUid();
      this.objectId = info.getObjectId();
      this.name = info.getDisplayName();
      this.avatar = info.getAvatarUrl();
      this.ginId = info.getId();
      this.status = info.getStatus();
      const ginRole = info.getRole();
      if (ginRole === GinRole.ADMIN) {
        this.role = "admin";
      } else if (ginRole === GinRole.AGENT) {
        this.role = "agent";
      } else if (ginRole === GinRole.COMPANY) {
        this.role = "company";
      } else {
        this.role = "";
      }
    }
  }
  toCommonCardProps() {
    return {
      uid: this.uid,
      objectId: this.objectId,
      ginId: this.ginId,
      name: this.name,
      avatar: this.avatar,
      status: this.status,
    };
  }
  setAvatar(avatar: string): void {
    this.avatar = avatar;
  }
}

class AgentUser extends GinUser {
  stars: GinUser[] = [];
  starsAmount = 0;
  restStarAmount = 0;
  MAXIMUM_STAR_LIST_AMOUNT = 7;
  constructor(info?: MemberInfo) {
    super(info);
    if (info) {
      this.starsAmount = info.getFollowersList().length;
      const users = info
        .getFollowersList()
        .slice(0, this.MAXIMUM_STAR_LIST_AMOUNT);
      this.restStarAmount = this.starsAmount - users.length;
      this.stars = users.map((e) => new GinUser(e));
    }
  }
}

class RegisterUser {
  registerId: number;
  uid: number;
  objectId: string;
  name: string;
  avatar: string;
  constructor(info: RegisterData) {
    this.registerId = info.getRegisterId();
    this.uid = info.getUid();
    this.objectId = info.getObjectId();
    this.name = info.getDisplayName();
    this.avatar = info.getAvatarUrl();
  }
  toCommonCardProps() {
    return {
      uid: this.uid,
      objectId: this.objectId,
      name: this.name,
      avatar: this.avatar,
    };
  }
}

function makeid(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

class Ranker {
  objectId = "";
  ginId = 0;
  name = "-";
  avatar = "";
  income = 0;
  isFirst = false;
  percentage = 0;
  rank = 0;
  constructor(res?: RevenueData) {
    if (res) {
      this.objectId = res.getObjectId();
      this.ginId = res.getId();
      this.name = res.getDisplayName();
      this.avatar = res.getAvatarUrl();
      this.income = res.getSum();
    }
  }
  setFakeData(): Ranker {
    this.name = makeid(15);
    this.avatar = "https://public.hare200.com/gin/home.jpeg";
    this.income = Math.floor(Math.random() * 10000);
    return this;
  }
}
class Ranking {
  title: string;
  scope: "agent" | "star";
  range:
    | "all"
    | "today"
    | "thisMonth"
    | "thisYear"
    | "yesterday"
    | "lastMonth"
    | "demo" = "all";
  rankers: Ranker[] = [];
  sourceType: SourceType;
  constructor(
    title: string,
    scope: "agent" | "star",
    source: "all" | "post" | "face"
  ) {
    this.title = title;
    this.scope = scope;
    if (source === "face") {
      this.sourceType = SourceType.S_FACE;
    } else if (source === "post") {
      this.sourceType = SourceType.S_POST;
    } else {
      this.sourceType = SourceType.S_ALL;
    }
  }
  setRankers(res?: RevenueRankingListRes): void {
    if (res) {
      this.rankers = res
        .getDataList()
        .map((e) => new Ranker(e))
        .sort((a, b) => b.income - a.income);
    } else {
      this.rankers = [];
      const randomCounts = Math.floor(Math.random() * 10);
      for (let index = 0; index < randomCounts; index++) {
        this.rankers.push(new Ranker().setFakeData());
      }
      this.rankers.sort((a, b) => b.income - a.income);
    }
    if (this.rankers.length > 0) {
      const placeholderAmount = 10 - this.rankers.length;
      for (let index = 0; index < placeholderAmount; index++) {
        this.rankers.push(new Ranker());
      }
    }
  }
  first3Rankers(): Ranker[] {
    const rankers = this.rankers.slice(0, 3);
    const topIncome = Math.max(...rankers.map((e) => e.income));
    rankers.map((e, index) => {
      e.percentage = e.income / topIncome;
      e.rank = index + 1;
    });
    return rankers;
  }
  otherRankers(): Ranker[] {
    return this.rankers.slice(3, this.rankers.length);
  }
  getRange(): { from: undefined | Timestamp; to: undefined | Timestamp } {
    if (this.range === "all") {
      return {
        from: undefined,
        to: undefined,
      };
    } else if (this.range === "today") {
      return {
        from: dayjs().startOf("day").toTimestamp(),
        to: dayjs().endOf("day").toTimestamp(),
      };
    } else if (this.range === "lastMonth") {
      return {
        from: dayjs().subtract(1, "month").startOf("month").toTimestamp(),
        to: dayjs().subtract(1, "month").endOf("month").toTimestamp(),
      };
    } else if (this.range === "thisMonth") {
      return {
        from: dayjs().startOf("month").toTimestamp(),
        to: dayjs().endOf("month").toTimestamp(),
      };
    } else if (this.range === "thisYear") {
      return {
        from: dayjs().startOf("year").toTimestamp(),
        to: dayjs().endOf("year").toTimestamp(),
      };
    } else if (this.range === "yesterday") {
      return {
        from: dayjs().subtract(1, "day").startOf("day").toTimestamp(),
        to: dayjs().subtract(1, "day").endOf("day").toTimestamp(),
      };
    } else {
      return {
        from: undefined,
        to: undefined,
      };
    }
  }
  getRangeName(): string {
    if (this.range === "all") {
      return "不限時間";
    } else if (this.range === "today") {
      return "今日";
    } else if (this.range === "lastMonth") {
      return "上個月";
    } else if (this.range === "thisMonth") {
      return "這個月";
    } else if (this.range === "thisYear") {
      return "今年";
    } else if (this.range === "yesterday") {
      return "昨天";
    } else {
      return "";
    }
  }
}

export {
  CompanyForm,
  StaffForm,
  AgentUser,
  GinUser,
  BaseUser,
  RegisterUser,
  Ranking,
  Ranker,
  UploadFileType,
};
