
import { defineComponent, inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { userStore } from "@/store/user";
import router from "@/router";
import User from "@/layouts/user.vue";
import MobileUser from "@/layouts/mobile_user.vue";
import { UpOutlined } from "@ant-design/icons-vue";
import { isMobile, isDesktop } from "@/lib/utils";
export default defineComponent({
  name: "Layout",
  components: { User, MobileUser, UpOutlined },
  setup() {
    const route = useRoute();
    const logout = () => {
      userStore.clearLoginSession();
      router.replace({ name: "login" });
    };
    const routes: {
      name: string;
      icon: string;
      path: string;
      match: string;
    }[] = [
      {
        name: "首頁",
        icon: "home",
        path: "/home",
        match: "home",
      },
      {
        name: "管理員列表",
        icon: "admin_panel_settings",
        path: "/admins",
        match: "adminCRUD",
      },
      {
        name: "經紀列表",
        icon: "face_retouching_natural",
        path: "/agents",
        match: "agentCRUD",
      },
      {
        name: "藝人列表",
        icon: "star",
        path: "/stars",
        match: "starCRUD",
      },
      {
        name: "待審核資料",
        icon: "library_add_check",
        path: "/registers",
        match: "registerCRUD",
      },
      {
        name: "總帳資料",
        icon: "sticky_note_2",
        path: "/income",
        match: "income",
      },
      {
        name: "經紀排行榜",
        icon: "emoji_events",
        path: "/rank/agent",
        match: "agentRank",
      },
      {
        name: "藝人排行榜",
        icon: "emoji_events",
        path: "/rank/star",
        match: "starRank",
      },
      // {
      //   name: "通告列表",
      //   icon: "calendar_month",
      //   path: "/posts",
      //   match: "postCRUD",
      // },
    ];
    const scrollToTop = () => {
      window.scrollTo(0, 0);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.route = route;
    };
    return {
      logout,
      routes,
      route,
      scrollToTop,
      isDesktop,
    };
  },
  computed: {
    selectedKeys(): string[] {
      const route = useRoute();
      return [...route.matched.map((e) => e.name?.toString() || "")];
    },
  },
});
