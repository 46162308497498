
import { defineComponent, toRefs, computed, onMounted } from "vue";
import {
  RegisterForm,
  BloodTypes,
  BoundaryTypes,
  humanCardName,
} from "@/models/register";
import { starStore } from "@/store/star";
export default defineComponent({
  name: "StarFormView",
  props: {
    isVisible: { default: true, type: Boolean },
    isPrivateDataVisible: { default: true, type: Boolean },
    myForm: { required: true, type: RegisterForm },
    isTagVisible: { default: true, type: Boolean },
    highlights: {
      default: () => {
        const defaultHighlights: string[] = [];
        return defaultHighlights;
      },
      type: Array,
    },
  },
  setup(props) {
    const tagList = starStore.tagList.value;
    const categoryList = starStore.categoryList.value;
    const matchedTags = computed(() => {
      return tagList.filter((e) => props.myForm.tagList.includes(e.id));
    });
    const matchedCategories = computed(() => {
      return categoryList.filter((e) => props.myForm.category === e.id);
    });
    const isSNSAreaVisible = computed(() => {
      return (
        props.myForm.jvid.length > 0 ||
        [
          ...props.myForm.facebookList.snsUrls,
          ...props.myForm.instagramList.snsUrls,
          ...props.myForm.twitterList.snsUrls,
          ...props.myForm.tiktokList.snsUrls,
        ].length > 0
      );
    });
    const cardName = (key: string): string => {
      return humanCardName.get(key) || "-";
    };
    return {
      ...toRefs(props),
      BloodTypes,
      BoundaryTypes,
      matchedTags,
      matchedCategories,
      isSNSAreaVisible,
      cardName,
    };
  },
});
