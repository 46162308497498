
import {
  defineComponent,
  reactive,
  toRefs,
  Ref,
  ref,
  onMounted,
  PropType,
  watch,
} from "vue";
import {
  PlusOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons-vue";
import { BaseUser } from "@/models/gin";
import CommonCard from "@/components/CommonCard.vue";
enum Step {
  select = 0,
  done = 1,
}
export default defineComponent({
  name: "UserSelector",
  components: { PlusOutlined, CommonCard, CloseOutlined, CheckOutlined },
  emits: ["onSelect"],
  props: {
    memberList: {
      required: true,
      type: Object as PropType<BaseUser[]>,
    },
    btnText: { default: "", type: String },
    title: { default: "", type: String },
  },
  setup(props, context) {
    const __state__: {
      isVisible: boolean;
      step: Step;
      selectedItem: null | BaseUser;
      selectedObjectId: string;
    } = {
      isVisible: false,
      step: Step.select,
      selectedItem: null,
      selectedObjectId: "",
    };
    const state = reactive(__state__);
    const showModal = () => {
      state.isVisible = true;
    };
    const closeModal = () => {
      state.isVisible = false;
    };
    const onSelect = (objectId: string) => {
      state.selectedItem =
        props.memberList.find((e) => e.objectId === objectId) || null;
      state.selectedObjectId = objectId;
    };
    const reset = () => {
      state.step = Step.select;
      state.selectedItem = null;
      state.selectedObjectId = "";
    };
    const cancel = () => {
      reset();
      closeModal();
    };
    const confirm = () => {
      context.emit("onSelect", {
        item: state.selectedItem,
        close: closeModal,
        toDone: toDone,
      });
    };
    const keepGoing = () => {
      reset();
    };
    const toDone = () => {
      state.step = Step.done;
    };
    watch(
      () => state.isVisible,
      (val, oldVal) => {
        if (val !== oldVal && val) {
          reset();
        }
      }
    );
    return {
      ...toRefs(state),
      ...toRefs(props),
      showModal,
      onSelect,
      Step,
      cancel,
      confirm,
      keepGoing,
    };
  },
});
