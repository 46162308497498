import Cookies from "js-cookie";
import { Ref, ref } from "vue";
import { authTokenKeyname, showPan } from "@/lib/pan";
import { User } from "@/models/user";
import { apiMember } from "@/lib/api_member";
import { useRouter } from "vue-router";
import { starStore } from "@/store/star";

const isLogin = ref(false);
const ginToken = ref("");
const currentUser: Ref<User> = ref(new User());
let timer: number;
const isDev: Ref<boolean> = ref(window.location.href.includes("dev"));

const getAccessToken = async (): Promise<string | null> => {
  const authToken = Cookies.get(authTokenKeyname);
  if (authToken) {
    return await window.dog.pan.api
      .AccessToken(authToken)
      .then((res) => res.getAccessToken());
  } else {
    return null;
  }
};

const getGinToken = async (panAccessToken: string): Promise<string> => {
  const res = await apiMember.login(panAccessToken);
  if (res.getIsValid()) {
    currentUser.value.role.setRole(res.getRole());
  }
  return res.getGinToken();
};

const fetchCurrentUser = async (panAccessToken: string): Promise<User> => {
  return window.dog.pan.api
    .GetMe(panAccessToken)
    .then((res) => res.getUser())
    .then((u) => {
      if (u) {
        return currentUser.value.setUser({
          uid: u.getUid(),
          objectId: u.getObjectId(),
          name: u.getDisplayName(),
          avatar: u.getAvatar(),
        });
      } else {
        return new User();
      }
    });
};
const initUser = async (): Promise<boolean> => {
  try {
    console.log("user.store initUser");

    const currentAccessToken = await getAccessToken();
    if (currentAccessToken) {
      // accessToken.value = currentAccessToken;
      currentUser.value = await fetchCurrentUser(currentAccessToken);
      ginToken.value = await getGinToken(currentAccessToken);
      currentUser.value.ginId = await apiMember
        .getMe(ginToken.value)
        .then((res) => res.getData()?.getId() || -1);
      isLogin.value = true;
      startTokenTicker();
      await starStore.init(ginToken.value);
    } else {
      isLogin.value = false;
      currentUser.value = new User();
    }
  } catch (error) {
    isLogin.value = false;
    currentUser.value = new User();
  }
  return isLogin.value;
};

const refreshToken = async (): Promise<boolean> => {
  try {
    const currentGinToken = await apiMember
      .refreshGinToken(ginToken.value)
      .then((res) => res.getGinToken());
    if (currentGinToken) {
      ginToken.value = currentGinToken;
      isLogin.value = true;
      return true;
    } else {
      isLogin.value = false;
      return false;
    }
  } catch (error) {
    isLogin.value = false;
    return false;
  }
};

const startTokenTicker = () => {
  const checkAuth = async () => {
    const result = await refreshToken();
    if (result === false) {
      clearInterval(timer);
      clearLoginSession();
      const router = useRouter();
      router.push({ name: "login" });
    }
  };

  timer = window.setInterval(checkAuth, 600000);
  document.removeEventListener("visibilitychange", checkAuth);
  document.addEventListener("visibilitychange", checkAuth);
};

const login = (callback?: () => void): void => {
  showPan(async (tokens) => {
    Cookies.set(authTokenKeyname, tokens.auth, { expires: 365 });
    await initUser();
    callback && callback();
  });
};

const clearLoginSession = (): void => {
  Cookies.remove(authTokenKeyname);
  ginToken.value = "";
  currentUser.value = new User();
  isLogin.value = false;
  clearInterval(timer);
};

export const userStore = {
  isLogin,
  currentUser,
  ginToken,
  login,
  clearLoginSession,
  initUser,
  getAccessToken,
  isDev,
};
