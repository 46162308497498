import { UploadFileType } from "@/models/gin";
import {
  ProfitOrderType,
  SourceType,
} from "@lctech-tw/gin-proto/dist/js/public/reward/enums_pb";
import {
  ContentType,
  GinRole,
  RegisterStatus,
  RevenueCategoryType,
  RevenueTargetType,
  SmsContentType,
} from "@lctech-tw/gin-proto/dist/js/star/atom/enums_pb";
import {
  RegisterDetail,
  StarDetail,
  StarPrivateData,
  StarProfileData,
} from "@lctech-tw/gin-proto/dist/js/star/atom/model_pb";
import { GinServicePromiseClient } from "@lctech-tw/gin-proto/dist/js/star/gin/gin_grpc_web_pb";
import * as starGinPb from "@lctech-tw/gin-proto/dist/js/star/gin/gin_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

const sourceTypes: { name: string; type: SourceType }[] = [
  { name: "全選", type: SourceType.S_ALL },
  { name: "JKFace", type: SourceType.S_FACE },
  { name: "JKFPost", type: SourceType.S_POST },
];
export interface ProfitParams {
  companyToken: string;
  page: number;
  limit: number;
  startAt?: Timestamp;
  endAt?: Timestamp;
  staffIdList?: number[];
  orderNo?: string;
  starObjectIdList?: string[];
  sourceType?: SourceType;
  giverObjectIdList?: string[];
  order?: "ascend" | "descend";
}
const setProfitParams = (
  payload: ProfitParams,
  req: starGinPb.ProfitRecordListReq | starGinPb.ProfitFilterStarReq
) => {
  req.setGinToken(payload.companyToken);
  payload.giverObjectIdList &&
    req.setGiverObjectIdList(payload.giverObjectIdList.flat());
  payload.orderNo && req.setOrderNo(payload.orderNo.toLowerCase());
  payload.sourceType && req.setSourceType(payload.sourceType);
  payload.staffIdList && req.setStaffIdList(payload.staffIdList.flat());
  payload.starObjectIdList &&
    req.setStarObjectIdList(payload.starObjectIdList.flat());
  payload.startAt && req.setStartAt(payload.startAt);
  payload.endAt && req.setEndAt(payload.endAt);
  payload.order &&
    req.setOrder(
      payload.order === "ascend"
        ? ProfitOrderType.PO_PROFIT_ASC
        : ProfitOrderType.PO_PROFIT_DESC
    );
  req.setPage(payload.page);
  req.setLimit(payload.limit);
};

const service = () => {
  const client = new GinServicePromiseClient(
    process.env.VUE_APP_STAR_HOST || ""
  );
  const enableDevTools =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__GRPCWEB_DEVTOOLS__ ||
    (() => {
      return;
    });
  enableDevTools([client]);
  return client;
};

const getProfitList = async (
  payload: ProfitParams
): Promise<starGinPb.ProfitRecordListRes> => {
  const req: starGinPb.ProfitRecordListReq =
    new starGinPb.ProfitRecordListReq();
  let res: starGinPb.ProfitRecordListRes;
  setProfitParams(payload, req);
  try {
    res = await service().profitRecordList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getStaffList = async (
  payload: {
    name?: string;
    role?: "admin" | "agent";
    status: boolean;
  },
  ginToken: string
): Promise<starGinPb.StaffListRes> => {
  const req: starGinPb.StaffListReq = new starGinPb.StaffListReq();
  let res: starGinPb.StaffListRes;
  req.setGinToken(ginToken);
  payload.name && req.setName(payload.name);
  if (payload.role === "admin") {
    req.setRole(GinRole.ADMIN);
  } else if (payload.role === "agent") {
    req.setRole(GinRole.AGENT);
  }
  req.setStatus(payload.status);
  try {
    res = await service().staffList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getStaffDetail = async (
  payload: {
    ginId: number;
  },
  ginToken: string
): Promise<starGinPb.StaffDetailRes> => {
  const req: starGinPb.StaffDetailReq = new starGinPb.StaffDetailReq();
  let res: starGinPb.StaffDetailRes;
  req.setGinToken(ginToken);
  req.setGinId(payload.ginId);
  try {
    res = await service().staffDetail(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getProfitFilterOptionsOfStars = async (
  token: string
): Promise<starGinPb.ProfitFilterStarRes> => {
  const req: starGinPb.ProfitFilterStarReq =
    new starGinPb.ProfitFilterStarReq();
  let res: starGinPb.ProfitFilterStarRes;
  req.setGinToken(token);
  req.setPage(1);
  req.setLimit(10);
  try {
    res = await service().profitFilterStar(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getProfitFilterOptionsOfGiver = async (
  payload: {
    name: string;
    page: number;
    limit: number;
  },
  token: string
): Promise<starGinPb.ProfitFilterGiverRes> => {
  const req: starGinPb.ProfitFilterGiverReq =
    new starGinPb.ProfitFilterGiverReq();
  let res: starGinPb.ProfitFilterGiverRes;
  req.setGinToken(token);
  req.setGiverName(payload.name);
  req.setPage(payload.page);
  req.setLimit(payload.limit);
  try {
    res = await service().profitFilterGiver(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getProfitFilterOptionsOfStaff = async (
  token: string
): Promise<starGinPb.ProfitFilterStaffRes> => {
  const req: starGinPb.ProfitFilterStaffReq =
    new starGinPb.ProfitFilterStaffReq();
  let res: starGinPb.ProfitFilterStaffRes;
  req.setGinToken(token);
  try {
    res = await service().profitFilterStaff(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getProfitOverview = async (
  payload: {
    from?: Timestamp;
    to?: Timestamp;
  },
  ginToken: string
): Promise<starGinPb.ProfitOverviewRes> => {
  const req: starGinPb.ProfitOverviewReq = new starGinPb.ProfitOverviewReq();
  let res: starGinPb.ProfitOverviewRes;
  req.setGinToken(ginToken);
  payload.from && req.setStartAt(payload.from);
  payload.to && req.setEndAt(payload.to);
  try {
    res = await service().profitOverview(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getStarList = async (
  payload: {
    staffGinId?: number;
    starObjectIds?: string[];
    name?: string;
    status: boolean;
    page: number;
    limit: number;
    isMine: boolean;
    uid?: number;
    phone?: string;
  },
  ginToken: string
): Promise<starGinPb.StarListRes> => {
  const req: starGinPb.StarListReq = new starGinPb.StarListReq();
  let res: starGinPb.StarListRes;
  req.setGinToken(ginToken);
  payload.staffGinId && req.setStaffId(payload.staffGinId);
  payload.starObjectIds && req.setStarObjectIdList(payload.starObjectIds);
  payload.name && req.setDisplayName(payload.name);
  payload.uid && req.setUid(payload.uid);
  payload.phone && req.setPhoneNumber(payload.phone);
  req.setIsMine(payload.isMine);
  req.setStatus(payload.status);
  req.setPage(payload.page);
  req.setLimit(payload.limit);
  try {
    res = await service().starList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const updateGinUser = async (
  payload: {
    objectId: string;
    status: boolean;
  },
  ginToken: string
): Promise<starGinPb.UpdateStaffInfoRes> => {
  const req: starGinPb.UpdateStaffInfoReq = new starGinPb.UpdateStaffInfoReq();
  let res: starGinPb.UpdateStaffInfoRes;
  req.setGinToken(ginToken);
  req.setObjectId(payload.objectId);
  req.setStatus(payload.status);
  try {
    res = await service().updateStaffInfo(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const setSubMemberAs = async (
  payload: {
    objectId: string;
    role: "admin" | "agent";
  },
  ginToken: string
): Promise<starGinPb.CreateStaffRes> => {
  const req: starGinPb.CreateStaffReq = new starGinPb.CreateStaffReq();
  let res: starGinPb.CreateStaffRes;
  req.setGinToken(ginToken);
  req.setObjectId(payload.objectId);
  if (payload.role === "admin") {
    req.setRole(GinRole.ADMIN);
  } else {
    req.setRole(GinRole.AGENT);
  }
  try {
    res = await service().createStaff(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const generateRegisterCode = async (
  payload: {
    ginId: number;
  },
  ginToken: string
): Promise<starGinPb.CreateVerifyCodeRes> => {
  const req: starGinPb.CreateVerifyCodeReq =
    new starGinPb.CreateVerifyCodeReq();
  let res: starGinPb.CreateVerifyCodeRes;
  req.setGinToken(ginToken);
  req.setStaffId(payload.ginId);
  try {
    res = await service().createVerifyCode(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getRegisterList = async (
  payload: {
    name?: string;
    status: "wait" | "trash";
  },
  ginToken: string
): Promise<starGinPb.StarRegisterListRes> => {
  const req: starGinPb.StarRegisterListReq =
    new starGinPb.StarRegisterListReq();
  let res: starGinPb.StarRegisterListRes;
  req.setGinToken(ginToken);
  // payload.name && req.setRealName(payload.name);
  payload.name && req.setDisplayName(payload.name);
  req.setLimit;
  req.setPage;
  if (payload.status === "wait") {
    req.setRegisterStatus(RegisterStatus.WAIT_VERIFY);
  } else {
    req.setRegisterStatus(RegisterStatus.TRASH);
  }
  try {
    res = await service().starRegisterList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getRegisterDetail = async (
  payload: {
    registerId: number;
  },
  ginToken: string
): Promise<starGinPb.StarRegisterDetailRes> => {
  const req: starGinPb.StarRegisterDetailReq =
    new starGinPb.StarRegisterDetailReq();
  let res: starGinPb.StarRegisterDetailRes;
  req.setGinToken(ginToken);
  req.setRegisterId(payload.registerId);
  try {
    res = await service().starRegisterDetail(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const saveRegisterProfile = async (
  payload: {
    profile: StarProfileData;
    privateData: StarPrivateData;
    registerId: number;
  },
  ginToken: string
): Promise<starGinPb.UpdateStarRegisterRes> => {
  const req: starGinPb.UpdateStarRegisterReq =
    new starGinPb.UpdateStarRegisterReq();
  let res: starGinPb.UpdateStarRegisterRes;
  req.setGinToken(ginToken);
  const registerDetail = new RegisterDetail();
  registerDetail.setRegisterId(payload.registerId);
  registerDetail.setPrivateData(payload.privateData);
  registerDetail.setProfile(payload.profile);
  req.setData(registerDetail);
  try {
    res = await service().updateStarRegister(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const changeRegisterProfileStatus = async (
  payload: {
    registerId: number;
    status: RegisterStatus;
  },
  ginToken: string
): Promise<starGinPb.VerifyStarProfileRes> => {
  const req: starGinPb.VerifyStarProfileReq =
    new starGinPb.VerifyStarProfileReq();
  let res: starGinPb.VerifyStarProfileRes;
  req.setGinToken(ginToken);
  req.setRegisterId(payload.registerId);
  req.setRegisterStatus(payload.status);
  req.setMemo;
  try {
    res = await service().verifyStarProfile(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const handleRegister = async (
  payload: {
    registerId: number;
    action: "trash" | "approve" | "recover";
  },
  ginToken: string
): Promise<starGinPb.VerifyStarProfileRes> => {
  if (payload.action === "trash") {
    return changeRegisterProfileStatus(
      { registerId: payload.registerId, status: RegisterStatus.TRASH },
      ginToken
    );
  } else if (payload.action === "approve") {
    return changeRegisterProfileStatus(
      { registerId: payload.registerId, status: RegisterStatus.CONFIRM },
      ginToken
    );
  } else {
    return changeRegisterProfileStatus(
      { registerId: payload.registerId, status: RegisterStatus.WAIT_VERIFY },
      ginToken
    );
  }
};

const getRevenueList = async (
  payload: {
    scope: "agent" | "star";
    category: "all" | "gift" | "post";
    from?: Timestamp;
    to?: Timestamp;
  },
  ginToken: string
): Promise<starGinPb.RevenueRankingListRes> => {
  const req: starGinPb.RevenueRankingListReq =
    new starGinPb.RevenueRankingListReq();
  let res: starGinPb.RevenueRankingListRes;
  req.setGinToken(ginToken);
  if (payload.category === "all") {
    req.setSourceType(SourceType.S_ALL);
  } else if (payload.category === "gift") {
    req.setSourceType(SourceType.S_FACE);
  } else {
    req.setSourceType(SourceType.S_POST);
  }
  if (payload.scope === "agent") {
    req.setTargetType(RevenueTargetType.RT_AGENT);
  } else {
    req.setTargetType(RevenueTargetType.RT_STAR);
  }
  payload.from && req.setStartAt(payload.from);
  payload.to && req.setEndAt(payload.to);
  req.setLimit(10);
  req.setPage(1);
  try {
    res = await service().revenueRankingList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const upload = async (
  payload: {
    fileName: string;
    type: UploadFileType;
    starObjectId: string;
  },
  ginToken: string
): Promise<starGinPb.UploadFileRes> => {
  const req: starGinPb.UploadFileReq = new starGinPb.UploadFileReq();
  let res: starGinPb.UploadFileRes;
  req.setGinToken(ginToken);
  req.setStarObjectId(payload.starObjectId);
  req.setFileName(payload.fileName);
  if (payload.type === UploadFileType.bank) {
    req.setType(ContentType.BANK_ACCOUNT);
  } else if (payload.type === UploadFileType.banner) {
    req.setType(ContentType.BANNER);
  } else if (payload.type === UploadFileType.idcard) {
    req.setType(ContentType.IDENTITY_CARD);
  } else if (payload.type === UploadFileType.logo) {
    req.setType(ContentType.LOGO);
  } else if (payload.type === UploadFileType.modelcard) {
    req.setType(ContentType.MODEL_CARD);
  }
  try {
    res = await service().uploadFileToGCS(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getStarFromStaffRevenueList = async (
  payload: {
    page: number;
    limit: number;
    staffId: number;
    sourceType: SourceType;
    from?: Timestamp;
    to?: Timestamp;
  },
  ginToken: string
): Promise<starGinPb.StarFromStaffRevenueRankingRes> => {
  const req: starGinPb.StarFromStaffRevenueRankingReq =
    new starGinPb.StarFromStaffRevenueRankingReq();
  let res: starGinPb.StarFromStaffRevenueRankingRes;
  req.setGinToken(ginToken);
  req.setStaffId(payload.staffId);
  req.setSourceType(payload.sourceType);
  req.setPage(payload.page);
  req.setLimit(payload.limit);
  req.setStartAt(payload.from);
  req.setEndAt(payload.to);
  try {
    res = await service().starFromStaffRevenueList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getStarTopFansRevenueList = async (
  payload: {
    page: number;
    limit: number;
    starObjectId: string;
    sourceType: SourceType;
    from?: Timestamp;
    to?: Timestamp;
  },
  ginToken: string
): Promise<starGinPb.StarTopFansRevenueListRes> => {
  const req: starGinPb.StarTopFansRevenueListReq =
    new starGinPb.StarTopFansRevenueListReq();
  let res: starGinPb.StarTopFansRevenueListRes;
  req.setGinToken(ginToken);
  req.setStarObjectId(payload.starObjectId);
  req.setSourceType(payload.sourceType);
  req.setPage(payload.page);
  req.setLimit(payload.limit);
  req.setStartAt(payload.from);
  req.setEndAt(payload.to);
  try {
    res = await service().starTopFansRevenueList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getStarDetail = async (
  payload: {
    uid: number;
  },
  ginToken: string
): Promise<starGinPb.StarDetailRes> => {
  const req: starGinPb.StarDetailReq = new starGinPb.StarDetailReq();
  let res: starGinPb.StarDetailRes;
  req.setGinToken(ginToken);
  req.setUid(payload.uid);
  try {
    res = await service().starDetail(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const updateStarProfile = async (
  payload: {
    profile: StarProfileData;
    privateData?: StarPrivateData;
    objectId: string;
  },
  ginToken: string
): Promise<starGinPb.UpdateStarProfileRes> => {
  const req: starGinPb.UpdateStarProfileReq =
    new starGinPb.UpdateStarProfileReq();
  let res: starGinPb.UpdateStarProfileRes;
  req.setGinToken(ginToken);
  const starDetail = new StarDetail();
  starDetail.setProfile(payload.profile);
  starDetail.setPrivateData(payload.privateData);
  req.setData(starDetail);
  req.setStarObjectId(payload.objectId);
  try {
    res = await service().updateStarProfile(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const updateStarStatus = async (
  payload: {
    uid: number;
    status: boolean;
  },
  ginToken: string
): Promise<starGinPb.UpdateStarInfoRes> => {
  const req: starGinPb.UpdateStarInfoReq = new starGinPb.UpdateStarInfoReq();
  let res: starGinPb.UpdateStarInfoRes;
  req.setGinToken(ginToken);
  req.setUid(payload.uid);
  req.setStatus(payload.status);
  try {
    res = await service().updateStarInfo(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getTagList = async (ginToken: string): Promise<starGinPb.TagListRes> => {
  const req: starGinPb.TagListReq = new starGinPb.TagListReq();
  let res: starGinPb.TagListRes;
  req.setGinToken(ginToken);
  try {
    res = await service().tagList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getCategoryList = async (
  ginToken: string
): Promise<starGinPb.CategoryListRes> => {
  const req: starGinPb.CategoryListReq = new starGinPb.CategoryListReq();
  let res: starGinPb.CategoryListRes;
  req.setGinToken(ginToken);
  try {
    res = await service().categoryList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const uploadAvatar = async (
  objectId: string,
  ginToken: string
): Promise<starGinPb.UploadAvatarRes> => {
  const req: starGinPb.UploadAvatarReq = new starGinPb.UploadAvatarReq();
  let res: starGinPb.UploadAvatarRes;
  req.setObjectId(objectId);
  req.setGinToken(ginToken);
  try {
    res = await service().uploadAvatar(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const sendSMS = async (
  payload: {
    content: string;
    phone: string;
  },
  ginToken: string
): Promise<starGinPb.SendSMSRes> => {
  const req: starGinPb.SendSMSReq = new starGinPb.SendSMSReq();
  let res: starGinPb.SendSMSRes;
  req.setContent(payload.content);
  req.setGinToken(ginToken);
  req.setPhoneNumber(payload.phone);
  req.setSmsType(SmsContentType.SMM_REVOKE_FILE);
  try {
    res = await service().sendSMSMessage(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const updateMemo = async (
  payload: {
    memo: string;
    registerId: number;
  },
  ginToken: string
): Promise<starGinPb.UpdateStarRegisterMemoRes> => {
  const req: starGinPb.UpdateStarRegisterMemoReq =
    new starGinPb.UpdateStarRegisterMemoReq();
  let res: starGinPb.UpdateStarRegisterMemoRes;
  req.setGinToken(ginToken);
  req.setMemo(payload.memo);
  req.setRegisterId(payload.registerId);
  try {
    res = await service().updateStarRegisterMemo(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

export const apiCompany = {
  getProfitList,
  getStaffList,
  getStaffDetail,
  getProfitOverview,
  getProfitFilterOptionsOfStars,
  getProfitFilterOptionsOfGiver,
  getProfitFilterOptionsOfStaff,
  sourceTypes,
  getStarList,
  updateGinUser,
  setSubMemberAs,
  generateRegisterCode,
  getRegisterList,
  getRegisterDetail,
  saveRegisterProfile,
  handleRegister,
  getRevenueList,
  upload,
  getStarFromStaffRevenueList,
  getStarTopFansRevenueList,
  getStarDetail,
  updateStarProfile,
  updateStarStatus,
  getTagList,
  getCategoryList,
  uploadAvatar,
  sendSMS,
  updateMemo,
};
