
import {
  defineComponent,
  toRefs,
  reactive,
  onMounted,
  computed,
  ref,
} from "vue";
import { BaseUser } from "@/models/gin";
import { userStore } from "@/store/user";
import { publicStore } from "@/store/public";
import { imageStore } from "@/store/image";
import { DownOutlined, UpOutlined } from "@ant-design/icons-vue";
import { Toast } from "@/lib/toast";
export default defineComponent({
  name: "StarCard",
  props: {
    currentLady: { required: true, type: BaseUser },
    currentAgentName: { default: "", type: String },
    isClosable: { default: true, type: Boolean },
    isDefaultVisible: { default: true, type: Boolean },
    highlights: {
      default: () => {
        const result: string[] = [];
        return result;
      },
      type: Array,
    },
    isEditing: { default: false, type: Boolean },
  },
  components: { DownOutlined, UpOutlined },
  setup(props) {
    const state = reactive({
      isVisible: true,
    });
    const beforeAvatarUpload = async (file: File) => {
      if (userStore.isLogin.value) {
        const result = await imageStore.uploadAvatarByStaff(
          { file: file, objectId: props.currentLady.objectId },
          userStore.ginToken.value
        );
        props.currentLady.setAvatar(result.url);
        Toast.info("頭像更新成功");
      } else {
        const result = await imageStore.uploadAvatarBySelf(
          { file: file },
          publicStore.accessToken.value
        );
        props.currentLady.setAvatar(result.url);
        Toast.info("頭像更新成功");
      }
    };
    const toggle = (bool: boolean) => {
      state.isVisible = bool;
    };
    onMounted(() => {
      state.isVisible = props.isDefaultVisible;
    });
    const isAvatarError = computed(() => {
      return (
        props.currentLady.avatar === imageStore.defaultAvatarImageUrl.value
      );
    });

    const defaultAvatar = imageStore.defaultAvatarImageUrl.value;
    const hintAvatar = imageStore.avatarHintImageUrl;

    const isCameraDropdownVisible = computed(() => {
      return userStore.isLogin.value;
    });

    const startUpload = () => {
      const input = document.querySelector(
        "#starCardUploader"
      ) as HTMLInputElement;
      input.click();
    };
    return {
      ...toRefs(props),
      ...toRefs(state),
      beforeAvatarUpload,
      toggle,
      defaultAvatar,
      hintAvatar,
      isAvatarError,
      isCameraDropdownVisible,
      startUpload,
    };
  },
});
