
import {
  SearchOutlined,
  LoadingOutlined,
  CloseOutlined,
} from "@ant-design/icons-vue";
import zhTW from "ant-design-vue/es/locale/zh_TW";
import { apiCompany, ProfitParams } from "@/lib/api_gin";
import { timestampToDayjs } from "@/lib/utils";
import { userStore } from "@/store/user";
import dayjs, { Dayjs } from "dayjs";
import "@/lib/time";
import {
  defineComponent,
  Ref,
  ref,
  onMounted,
  computed,
  reactive,
  toRefs,
  watch,
  h,
} from "vue";
import { readObjectByObjectIds } from "@/lib/pan";
import InfiniteScroll from "infinite-scroll";
import { ColumnsType } from "ant-design-vue/lib/table";
import _ from "lodash";
import TableLoader from "@/components/TableLoader.vue";
interface MyProfitParams extends ProfitParams {
  from: undefined | Dayjs;
  to: undefined | Dayjs;
  current: number;
  total: number;
  showSizeChanger: boolean;
  pageSize: number;
}
enum Range {
  none = "",
  today = "today",
  thisMonth = "thisMonth",
  thisYear = "thisYear",
  yesterday = "yesterday",
  lastMonth = "lastMonth",
}
interface FilterOption {
  label: string;
  value: string | number;
}
enum FilterScope {
  none = "",
  name = "name",
  giver = "giver",
  staff = "staff",
  source = "source",
  order = "order",
  datetime = "datetime",
}
const checkedList: (string | number)[] = [];

export default defineComponent({
  name: "ViewIncome",
  components: { SearchOutlined, LoadingOutlined, CloseOutlined },
  setup() {
    const state = reactive({
      isLoadingProfitRecordList: false,
      isLoadingProfitOverview: false,
      isLoadingFilterOptions: false,
      filterScope: FilterScope.none,
      searchValue: "",
      checkAll: false,
      indeterminateAll: false,
      checkedList: checkedList,
      range: "",
      giverPage: 1,
      noMoreGivers: false,
    });
    const isAgentScope = computed(() => {
      return userStore.currentUser.value.role.isAgent();
    });
    const rangeButtons: {
      name: string;
      value: Range;
    }[] = [
      { name: "今日", value: Range.today },
      { name: "這個月", value: Range.thisMonth },
      { name: "今年", value: Range.thisYear },
      { name: "昨天", value: Range.yesterday },
      { name: "上個月", value: Range.lastMonth },
    ];
    const baseProfitColumns: ColumnsType = [
      {
        title: "UID",
        dataIndex: "uid",
      },
      {
        title: "藝人名稱",
        dataIndex: FilterScope.name,
        customFilterDropdown: true,
        onFilterDropdownVisibleChange: async (visible: boolean) => {
          if (visible) {
            await getFilterOptions(FilterScope.name);
          }
        },
      },
      {
        title: "收益來源",
        dataIndex: FilterScope.source,
        customFilterDropdown: true,
        onFilterDropdownVisibleChange: async (visible: boolean) => {
          if (visible) {
            await getFilterOptions(FilterScope.source);
          }
        },
      },
      {
        title: "付款者名稱",
        dataIndex: FilterScope.giver,
        customFilterDropdown: true,
        onFilterDropdownVisibleChange: async (visible: boolean) => {
          if (visible) {
            await getGiverFilterOptions(1);
          }
        },
      },
      {
        title: "收益金額",
        dataIndex: "profit",
        sorter: true,
      },
      {
        title: "訂單號碼",
        dataIndex: FilterScope.order,
        customFilterDropdown: true,
      },
      {
        title: "收款日期",
        dataIndex: FilterScope.datetime,
        customFilterDropdown: true,
      },
    ];
    const fullProfitColumns: ColumnsType = _.cloneDeep(baseProfitColumns);
    fullProfitColumns.push({
      title: "經紀名稱",
      dataIndex: FilterScope.staff,
      customFilterDropdown: true,
      onFilterDropdownVisibleChange: async (visible: boolean) => {
        if (visible) {
          await getFilterOptions(FilterScope.staff);
        }
      },
    });
    const profitList: Ref<
      {
        uid: number;
        name: string;
        source: string;
        giver: string;
        profit: number;
        order: string;
        datetime: string;
        staff: string;
      }[]
    > = ref([]);
    const originalProfitParams: MyProfitParams = {
      companyToken: userStore.ginToken.value,
      current: 1,
      page: 1,
      limit: 50,
      pageSize: 50,
      total: 0,
      showSizeChanger: false,
      starObjectIdList: undefined,
      giverObjectIdList: undefined,
      startAt: undefined,
      endAt: undefined,
      from: undefined,
      to: undefined,
      sourceType: 0,
      orderNo: "",
    };
    const profitParams = reactive(originalProfitParams);
    const getProfitList = async (page: number, confirm?: any) => {
      state.isLoadingProfitRecordList = true;
      profitParams.page = page;
      profitParams.current = page;
      profitParams.startAt = profitParams.from?.startOf("date").toTimestamp();
      profitParams.endAt = profitParams.to?.endOf("date").toTimestamp();
      const payload = {
        ...profitParams,
      };
      payload.companyToken = userStore.ginToken.value;
      if (isAgentScope.value) {
        payload.staffIdList = [userStore.currentUser.value.ginId];
      }
      const res = await apiCompany.getProfitList(payload);
      const users = await readObjectByObjectIds([
        ...res.getRecordList().map((e) => e.getStarObjectId()),
        ...res.getRecordList().map((e) => e.getGiverObjectId()),
      ]).then((objects) =>
        objects.map((e) => ({
          uid: e.getUid(),
          name: e.getDisplayName(),
          objectId: e.getObjectId(),
        }))
      );

      const staffs = await apiCompany
        .getStaffList({ status: true }, userStore.ginToken.value)
        .then((response) =>
          response
            .getDataList()
            .map((e) => ({ id: e.getId(), name: e.getDisplayName() }))
        );
      profitList.value = res.getRecordList().map((e) => {
        const lady = users.find((i) => i.objectId === e.getStarObjectId());
        const giver = users.find((i) => i.objectId === e.getGiverObjectId());
        const staff = staffs.find((i) => i.id === e.getStaffId());
        return {
          uid: lady?.uid || 0,
          name: lady?.name || "-",
          source: humanSourceType(e.getSource()),
          giver: giver?.name || "-",
          profit: e.getProfit(),
          order: e.getOrderNo(),
          datetime:
            timestampToDayjs(e.getCreatedAt())?.format("YYYY-MM-DD HH:mm:ss") ||
            "",
          staff: staff?.name || "-",
        };
      });
      // profitList.value = [
      //   {
      //     uid: 123,
      //     name: "hehe",
      //     source: "嘿嘿",
      //     giver: "呼呼",
      //     profit: 123456,
      //     order: "edwdweewd",
      //     datetime: "ewfwefewfw",
      //     staff: "hehe",
      //   },
      // ];
      profitParams.total = res.getTotal();
      state.isLoadingProfitRecordList = false;
      confirm && confirm();
    };
    const profitOverview = ref({
      current: 0,
      unpaid: 0,
      accumulate: 0,
      currentPivot: dayjs(),
      isThisMonth: true,
    });
    const getProfitOverview = async (action?: "previous" | "next") => {
      if (state.isLoadingProfitOverview === false) {
        state.isLoadingProfitOverview = true;
        if (action === "next") {
          profitOverview.value.currentPivot =
            profitOverview.value.currentPivot.add(1, "month");
        } else if (action === "previous") {
          profitOverview.value.currentPivot =
            profitOverview.value.currentPivot.add(-1, "month");
        }
        const pivot = profitOverview.value.currentPivot;
        const today = dayjs();
        profitOverview.value.isThisMonth =
          pivot.year() === today.year() && pivot.month() === today.month();
        const res = await apiCompany.getProfitOverview(
          {
            from: pivot.startOf("month").toTimestamp(),
            to: pivot.endOf("month").toTimestamp(),
          },
          userStore.ginToken.value
        );
        profitOverview.value.current = res.getCurrentperiodamount();
        profitOverview.value.accumulate = res.getAccumulateamount();
        profitOverview.value.unpaid = res.getUnpaidamount();
        setTimeout(() => {
          state.isLoadingProfitOverview = false;
        }, 250);
      }
    };
    onMounted(() => {
      state.checkedList = [];
      getProfitList(1);
      getProfitOverview();
    });

    const searchInput = ref();
    const onCheckAllChange = (e: any) => {
      if (e.target.checked) {
        state.checkedList = filterOptions.value.map((option) => option.value);
      } else {
        state.checkedList = [];
      }
      applyFilterOptions();
    };
    watch(
      () => state.checkedList,
      (val) => {
        state.indeterminateAll =
          !!val.length && val.length !== filterOptions.value.length;
        state.checkAll =
          state.checkedList.length === 0
            ? false
            : val.length === filterOptions.value.length;
      }
    );
    const filterOptions: Ref<FilterOption[]> = ref([]);
    const selectedGiverFilterOptions: Ref<FilterOption[]> = ref([]);
    const searchedFilterOptions = computed(() => {
      return filterOptions.value.filter(
        (e) =>
          e.label.toLowerCase().includes(state.searchValue.toLowerCase()) ||
          state.checkedList.includes(e.value)
      );
    });
    const getFilterOptions = async (scope: FilterScope) => {
      state.filterScope = scope;
      filterOptions.value = [];
      setTimeout(() => {
        searchInput.value.focus();
      }, 100);
      if (scope === FilterScope.name) {
        const res = await apiCompany.getProfitFilterOptionsOfStars(
          userStore.ginToken.value
        );
        const options = res.getStarInfoList().map((e) => ({
          label: e.getDisplayName(),
          value: e.getObjectId(),
        }));
        filterOptions.value = options;
        const starIds = profitParams.starObjectIdList;
        if (typeof starIds === "object" && starIds.length > 0) {
          state.checkedList = starIds;
        } else {
          state.checkedList = [];
        }
        state.searchValue = "";
      } else if (scope === FilterScope.staff) {
        const res = await apiCompany.getProfitFilterOptionsOfStaff(
          userStore.ginToken.value
        );
        const options = res.getStaffInfoList().map((e) => ({
          label: e.getDisplayName(),
          value: e.getId(),
        }));
        filterOptions.value = options;
        const staffIds = profitParams.staffIdList;
        if (typeof staffIds === "object" && staffIds.length > 0) {
          state.checkedList = staffIds;
        } else {
          state.checkedList = [];
        }
        state.searchValue = "";
      } else if (scope === FilterScope.source) {
        const options = apiCompany.sourceTypes.map((e) => ({
          label: e.name,
          value: e.type,
        }));
        filterOptions.value = options;
      }
    };
    const getGiverFilterOptions = async (page: number) => {
      if (!state.isLoadingFilterOptions) {
        if (page > 1 && state.noMoreGivers) {
          return;
        }
        state.isLoadingFilterOptions = true;
        state.giverPage = page;
        state.filterScope = FilterScope.giver;
        if (page === 1) {
          filterOptions.value = [];
          filterOptions.value = selectedGiverFilterOptions.value;
        }
        setTimeout(() => {
          searchInput.value.focus();
        }, 100);
        const res = await apiCompany.getProfitFilterOptionsOfGiver(
          { name: state.searchValue, page: page, limit: 20 },
          userStore.ginToken.value
        );
        const options = res.getGiverInfoList().map((e) => ({
          label: e.getDisplayName(),
          value: e.getObjectId(),
        }));
        filterOptions.value = filterOptions.value.concat(
          options.filter(
            (e) =>
              !selectedGiverFilterOptions.value
                .map((i) => i.value)
                .includes(e.value)
          )
        );
        if (page === 1) {
          const giverIds = profitParams.giverObjectIdList;
          if (typeof giverIds === "object" && giverIds.length > 0) {
            state.checkedList = giverIds;
          } else {
            state.checkedList = [];
          }
          state.noMoreGivers = false;
          setTimeout(() => {
            let infScroll = new InfiniteScroll("#givers", {
              path: "page{{#}}", // hack
              loadOnScroll: false, // disable loading
              history: false,
            });
            infScroll.on("scrollThreshold", async () => {
              const options = await getGiverFilterOptions(state.giverPage + 1);
              if (typeof options === "object" && options.length === 0) {
                state.noMoreGivers = true;
              }
            });
          }, 500);
        }
        state.isLoadingFilterOptions = false;
        return options;
      }
    };
    const applyFilterOptions = () => {
      if (state.filterScope === FilterScope.name) {
        profitParams.starObjectIdList = state.checkedList
          .flat()
          .map((e) => e.toString());
      } else if (state.filterScope === FilterScope.giver) {
        profitParams.giverObjectIdList = state.checkedList
          .flat()
          .map((e) => e.toString());
        selectedGiverFilterOptions.value = filterOptions.value.filter((e) =>
          state.checkedList.includes(e.value)
        );
      } else if (state.filterScope === FilterScope.staff) {
        profitParams.staffIdList = state.checkedList
          .flat()
          .map((e) => (typeof e === "number" ? e : Number(e)));
      }
    };
    const clearFilterOptions = (scope: FilterScope, confirm: any) => {
      if (scope === FilterScope.giver) {
        profitParams.giverObjectIdList = undefined;
        state.searchValue = "";
      } else if (scope === FilterScope.name) {
        profitParams.starObjectIdList = undefined;
      } else if (scope === FilterScope.source) {
        profitParams.sourceType = 0;
      } else if (scope === FilterScope.staff) {
        profitParams.staffIdList = undefined;
      } else if (scope === FilterScope.order) {
        profitParams.orderNo = undefined;
      } else if (scope === FilterScope.datetime) {
        profitParams.from = undefined;
        profitParams.to = undefined;
      }
      confirm();
      getProfitList(1);
    };
    const applyFastRange = async (range: Range) => {
      if (range === state.range) {
        profitParams.from = undefined;
        profitParams.to = undefined;
        state.range = Range.none;
      } else {
        if (range === Range.today) {
          profitParams.from = dayjs().startOf("date");
          profitParams.to = dayjs().endOf("date");
        } else if (range === Range.thisMonth) {
          profitParams.from = dayjs().startOf("month");
          profitParams.to = dayjs().endOf("month");
        } else if (range === Range.thisYear) {
          profitParams.from = dayjs().startOf("year");
          profitParams.to = dayjs().endOf("year");
        } else if (range === Range.yesterday) {
          profitParams.from = dayjs().subtract(1, "day").startOf("date");
          profitParams.to = dayjs().subtract(1, "day").endOf("date");
        } else if (range === Range.lastMonth) {
          profitParams.from = dayjs().subtract(1, "month").startOf("month");
          profitParams.to = dayjs().subtract(1, "month").endOf("month");
        }
        state.range = range;
      }

      await getProfitList(1);
    };
    const onTableChange = async (
      pagination: any,
      filters: any,
      sorter: { field: string; order: "ascend" | "descend" }
    ) => {
      if (sorter.field === "profit") {
        profitParams.order = sorter.order;
      }
      await getProfitList(pagination.current);
    };
    const isFilterBy = (scope: FilterScope): boolean => {
      if (scope === FilterScope.name) {
        return (profitParams.starObjectIdList?.length || 0) > 0;
      } else if (scope === FilterScope.giver) {
        return (profitParams.giverObjectIdList?.length || 0) > 0;
      } else if (scope === FilterScope.staff) {
        return (profitParams.staffIdList?.length || 0) > 0;
      } else if (scope === FilterScope.source) {
        return profitParams.sourceType !== 0;
      } else if (scope === FilterScope.order) {
        return Boolean(profitParams.orderNo);
      } else if (scope === FilterScope.datetime) {
        return Boolean(profitParams.from) || Boolean(profitParams.to);
      } else {
        return false;
      }
    };
    const humanSourceType = (sourceType: number): string => {
      const type = apiCompany.sourceTypes.find((e) => e.type === sourceType);
      if (type) {
        return type.name;
      } else {
        return "-";
      }
    };
    const sourceTypes = reactive(apiCompany.sourceTypes);
    const tableLoader = {
      indicator: h(TableLoader),
    };
    return {
      profitList,
      fullProfitColumns,
      baseProfitColumns,
      profitOverview,
      getProfitOverview,
      searchInput,
      searchedFilterOptions,
      profitParams,
      onCheckAllChange,
      getGiverFilterOptions,
      applyFilterOptions,
      clearFilterOptions,
      ...toRefs(state),
      rangeButtons,
      applyFastRange,
      onTableChange,
      isFilterBy,
      sourceTypes,
      FilterScope,
      zhTW,
      getProfitList,
      isAgentScope,
      tableLoader,
    };
  },
});
