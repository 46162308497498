
import { defineComponent, toRefs, computed } from "vue";
import { EditFilled, EyeFilled, CheckOutlined } from "@ant-design/icons-vue";
import { userStore } from "@/store/user";
export default defineComponent({
  name: "CommonCard",
  components: { CheckOutlined },
  emits: ["onSelect"],
  props: {
    isFootVisible: { default: true, type: Boolean },
    isSelectable: { default: false, type: Boolean },
    isSelected: { default: false, type: Boolean },
    isStatusVisible: { default: true, type: Boolean },
    isLinkToProfile: { default: false, type: Boolean },
    uid: { required: true, type: Number },
    objectId: { required: true, type: String },
    ginId: { required: false, type: Number },
    name: { required: true, type: String },
    avatar: { required: true, type: String },
    status: { type: Boolean },
  },
  setup(props, context) {
    const isMe = computed(() => {
      return props.uid === userStore.currentUser.value.uid;
    });
    const onSelect = () => {
      if (props.isSelectable) {
        context.emit("onSelect", props.objectId);
      }
    };
    return {
      ...toRefs(props),
      onSelect,
      isMe,
    };
  },
});
