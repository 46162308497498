
import { userStore } from "@/store/user";
import {
  defineComponent,
  Ref,
  ref,
  computed,
  reactive,
  toRefs,
  onMounted,
} from "vue";
import { useRoute } from "vue-router";
import RegisterFormComponent from "@/components/StarForm.vue";
import { CloseOutlined } from "@ant-design/icons-vue";
import { apiCompany } from "@/lib/api_gin";
import { Toast } from "@/lib/toast";
import { RegisterForm } from "@/models/register";
import GoBackButton from "@/components/GoBackButton.vue";
import StarStatusChanger from "@/components/StarStatusChanger.vue";
import { AgentUser, BaseUser, UploadFileType } from "@/models/gin";
import Loader from "@/components/Loader.vue";
import _ from "lodash";

export default defineComponent({
  name: "ViewStarDetail",
  components: {
    RegisterFormComponent,
    CloseOutlined,
    GoBackButton,
    StarStatusChanger,
    Loader,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      status: false,
      isEditing: Boolean(route.query.edit),
      isLoading: false,
    });
    const starObjectId = String(route.query.starObjectId) as string;
    const uid = Number(route.params.uid as string);
    const currentForm: Ref<RegisterForm> = ref(new RegisterForm());
    const editedForm: Ref<RegisterForm> = ref(new RegisterForm());
    const currentAgent: Ref<AgentUser> = ref(new AgentUser());
    const currentLady: Ref<BaseUser> = ref(new BaseUser());
    const isEditable = computed(() => {
      const currentUser = userStore.currentUser.value;
      return (
        currentUser.role.isCompany() ||
        currentUser.role.isAdmin() ||
        currentUser.ginId === currentAgent.value.ginId
      );
    });

    const handleSubmit = async () => {
      state.isLoading = true;
      try {
        await apiCompany.updateStarProfile(
          {
            profile: editedForm.value.toStarProfileData(),
            privateData: editedForm.value.toStarUpdatePrivateData(),
            objectId: starObjectId,
          },
          userStore.ginToken.value
        );
        toggleEdit(false);
        Toast.info("已儲存成功");
        currentForm.value = _.cloneDeep(editedForm.value);
      } catch (error) {
        Toast.bugOccur();
      } finally {
        state.isLoading = false;
      }
    };
    const validate = (form: RegisterForm) => {
      editedForm.value = form;

      const submitButton = document.querySelector(
        "#register_submit_btn"
      ) as HTMLButtonElement;

      const yes = confirm("確定儲存資料嗎？");
      if (yes) {
        submitButton.click();
      }
    };
    const toggleEdit = (bool: boolean) => {
      state.isEditing = bool;
    };

    const getStarDetail = async () => {
      const res = await apiCompany.getStarDetail(
        { uid: uid },
        userStore.ginToken.value
      );
      currentForm.value = new RegisterForm(res);
      state.status = res.getData()?.getStatus() || false;
      const info = res.getData()?.getStaffInfo();
      if (info) {
        currentAgent.value = new AgentUser(info);
      }
    };

    const getCurrentLady = async () => {
      const res = await window.dog.pan.api.ReadObjectByObjectId(starObjectId);
      currentLady.value = new BaseUser(res);
    };

    const isPrivateDataVisible = computed(() => {
      const currentUser = userStore.currentUser.value;
      return currentUser.role.isCompany() || currentUser.role.isAdmin();
    });

    onMounted(async () => {
      state.isLoading = true;
      await getStarDetail();
      await getCurrentLady();
      state.isLoading = false;
    });

    return {
      ...toRefs(state),
      starObjectId,
      validate,
      uid,
      toggleEdit,
      currentForm,
      currentLady,
      currentAgent,
      handleSubmit,
      isEditable,
      isPrivateDataVisible,
    };
  },
});
