import { UploadFileType } from "@/models/gin";
import { ContentType } from "@lctech-tw/gin-proto/dist/js/star/atom/enums_pb";
import {
  StarPrivateData,
  StarProfileData,
} from "@lctech-tw/gin-proto/dist/js/star/atom/model_pb";
import { StarServicePromiseClient } from "@lctech-tw/gin-proto/dist/js/star/stars/stars_grpc_web_pb";
import * as starsPb from "@lctech-tw/gin-proto/dist/js/star/stars/stars_pb";

const service = () => {
  const client = new StarServicePromiseClient(
    process.env.VUE_APP_STAR_HOST || ""
  );
  const enableDevTools =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__GRPCWEB_DEVTOOLS__ ||
    (() => {
      return;
    });
  enableDevTools([client]);
  return client;
};

const saveProfile = async (
  payload: {
    code: string;
    profile: StarProfileData;
    privateData: StarPrivateData;
  },
  accessToken: string
): Promise<starsPb.SaveProfileRes> => {
  const req: starsPb.SaveProfileReq = new starsPb.SaveProfileReq();
  let res: starsPb.SaveProfileRes;
  req.setAccessToken(accessToken);
  req.setVerifyCode(payload.code);
  req.setProfile(payload.profile);
  req.setPrivateData(payload.privateData);
  try {
    res = await service().saveProfile(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getMyRegisterProfile = async (
  payload: { code: string },
  accessToken: string
): Promise<starsPb.MyRegisterProfileRes> => {
  const req: starsPb.MyRegisterProfileReq = new starsPb.MyRegisterProfileReq();
  let res: starsPb.MyRegisterProfileRes;
  req.setAccessToken(accessToken);
  req.setVerifyCode(payload.code);
  try {
    res = await service().myRegisterProfile(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const checkVerifyCode = async (
  code: string
): Promise<starsPb.CheckVerifyCodeRes> => {
  const req: starsPb.CheckVerifyCodeReq = new starsPb.CheckVerifyCodeReq();
  let res: starsPb.CheckVerifyCodeRes;
  req.setVerifyCode(code);
  try {
    res = await service().checkVerifyCode(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const uploadFile = async (
  payload: {
    fileName: string;
    type: UploadFileType;
  },
  accessToken: string
): Promise<starsPb.UploadFileRes> => {
  const req: starsPb.UploadFileReq = new starsPb.UploadFileReq();
  let res: starsPb.UploadFileRes;
  req.setAccessToken(accessToken);
  req.setFileName(payload.fileName);
  if (payload.type === UploadFileType.bank) {
    req.setType(ContentType.BANK_ACCOUNT);
  } else if (payload.type === UploadFileType.banner) {
    req.setType(ContentType.BANNER);
  } else if (payload.type === UploadFileType.idcard) {
    req.setType(ContentType.IDENTITY_CARD);
  } else if (payload.type === UploadFileType.logo) {
    req.setType(ContentType.LOGO);
  } else if (payload.type === UploadFileType.modelcard) {
    req.setType(ContentType.MODEL_CARD);
  }
  try {
    res = await service().uploadFileToGCS(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const uploadAvatar = async (
  accessToken: string
): Promise<starsPb.UploadAvatarRes> => {
  const req: starsPb.UploadAvatarReq = new starsPb.UploadAvatarReq();
  let res: starsPb.UploadAvatarRes;
  req.setAccessToken(accessToken);
  try {
    res = await service().uploadAvatar(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

export const apiStar = {
  saveProfile,
  getMyRegisterProfile,
  checkVerifyCode,
  uploadFile,
  uploadAvatar,
};
