import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c917101e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card-check"
}
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "info" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "info-avatar g-avatar-container"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "info-status_list" }
const _hoisted_9 = {
  key: 0,
  class: "info-status info-status--me"
}
const _hoisted_10 = {
  key: 0,
  class: "info-status"
}
const _hoisted_11 = {
  key: 1,
  class: "info-status info-status--suspend"
}
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "info-name" }
const _hoisted_15 = { class: "info-uid" }
const _hoisted_16 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check_outlined = _resolveComponent("check-outlined")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", { 'card--selectable': _ctx.isSelectable, 'card--selected': _ctx.isSelected }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSelect && _ctx.onSelect(...args)))
  }, [
    (_ctx.isSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_check_outlined)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isLinkToProfile)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "info-avatar g-avatar-container",
              target: "_blank",
              href: `https://jkface.net/profile/${_ctx.uid}`
            }, [
              _createElementVNode("img", { src: _ctx.avatar }, null, 8, _hoisted_5)
            ], 8, _hoisted_4))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("img", { src: _ctx.avatar }, null, 8, _hoisted_7)
            ])),
        _createElementVNode("div", _hoisted_8, [
          (_ctx.isStatusVisible)
            ? _renderSlot(_ctx.$slots, "status", { key: 0 }, () => [
                (_ctx.isMe)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, "自己"))
                  : _createCommentVNode("", true),
                (!_ctx.isSelectable)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.status)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, "啟用"))
                        : (_openBlock(), _createElementBlock("div", _hoisted_11, "停用"))
                    ], 64))
                  : _createCommentVNode("", true)
              ], true)
            : _createCommentVNode("", true)
        ]),
        (_ctx.isLinkToProfile)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createElementVNode("a", {
                class: "info-name",
                target: "_blank",
                href: `https://jkface.net/profile/${_ctx.uid}`
              }, _toDisplayString(_ctx.name), 9, _hoisted_12),
              _createElementVNode("a", {
                class: "info-uid",
                target: "_blank",
                href: `https://jkface.net/profile/${_ctx.uid}`
              }, "UID " + _toDisplayString(_ctx.uid), 9, _hoisted_13)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.name), 1),
              _createElementVNode("div", _hoisted_15, "UID " + _toDisplayString(_ctx.uid), 1)
            ], 64))
      ])
    ]),
    (_ctx.isFootVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _renderSlot(_ctx.$slots, "foot", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}