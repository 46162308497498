import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-128e2e48"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "rank-page"
}
const _hoisted_2 = { class: "rank-title" }
const _hoisted_3 = { class: "rank-scope" }
const _hoisted_4 = { class: "rank-podium" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "rank-podium-item-avatar g-avatar-container" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "rank-podium-item-name" }
const _hoisted_9 = { class: "rank-podium-item-income dollar-prefix" }
const _hoisted_10 = {
  key: 0,
  class: "rank-list"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "rank-list-item-avatar g-avatar-container" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "rank-list-item-name" }
const _hoisted_15 = { class: "rank-list-item-income dollar-prefix" }
const _hoisted_16 = {
  key: 1,
  class: "rank-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_caret_down_outlined = _resolveComponent("caret-down-outlined")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_Empty = _resolveComponent("Empty")!
  const _component_RankDetail = _resolveComponent("RankDetail")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList({
        all: _ctx.allRanking,
        gift: _ctx.giftRanking,
        post: _ctx.postRanking,
      }, (ranking, rankingKey) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["rank", {
        'rank--purple': rankingKey === 'gift',
        'rank--red': rankingKey === 'post',
      }]),
              key: rankingKey
            }, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(ranking.title) + " 排行榜", 1),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_a_select, {
                  class: "rank-scope-select",
                  value: ranking.range,
                  "onUpdate:value": ($event: any) => ((ranking.range) = $event),
                  ref_for: true,
                  ref: "select",
                  onChange: ($event: any) => (_ctx.getRankings(ranking))
                }, {
                  suffixIcon: _withCtx(() => [
                    _createVNode(_component_caret_down_outlined)
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ranges, (range, rangeKey) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        value: rangeKey,
                        key: rangeKey
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(range.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["value", "onUpdate:value", "onChange"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ranking.first3Rankers(), (ranker, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["rank-podium-item", {
            'rank-podium-item--first': ranker.rank === 1,
            'rank-podium-item--second': ranker.rank === 2,
            'rank-podium-item--third': ranker.rank === 3,
          }]),
                    key: index,
                    onClick: ($event: any) => (_ctx.showDetail(ranking, ranker))
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      (ranker.avatar)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: ranker.avatar
                          }, null, 8, _hoisted_7))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(ranker.name), 1),
                    _createElementVNode("div", _hoisted_9, _toDisplayString((ranker.income || "-").toLocaleString()), 1),
                    _createElementVNode("div", {
                      class: _normalizeClass(["rank-podium-item-chart", { 'rank-podium-item-chart--grey': ranker.income <= 0 }]),
                      style: _normalizeStyle({ height: ranker.percentage * 200 + 'px' })
                    }, _toDisplayString(ranker.rank), 7)
                  ], 10, _hoisted_5))
                }), 128))
              ]),
              (ranking.rankers.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ranking.otherRankers(), (ranker, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "rank-list-item",
                        key: index,
                        onClick: ($event: any) => (_ctx.showDetail(ranking, ranker))
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["rank-list-item-ranking", { 'rank-list-item-ranking--lighter': index >= 2 }])
                        }, _toDisplayString(index + 4), 3),
                        _createElementVNode("div", _hoisted_12, [
                          (ranker.avatar)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: ranker.avatar
                              }, null, 8, _hoisted_13))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_14, _toDisplayString(ranker.name), 1),
                        _createElementVNode("div", _hoisted_15, _toDisplayString((ranker.income || "-").toLocaleString()), 1)
                      ], 8, _hoisted_11))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createVNode(_component_Empty)
                  ]))
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.selectedRanker && _ctx.selectedRanking)
      ? (_openBlock(), _createBlock(_component_RankDetail, {
          key: 1,
          isRemoteVisible: _ctx.isDetailVisible,
          ranker: _ctx.selectedRanker,
          ranking: _ctx.selectedRanking,
          onClose: _ctx.closeDetail
        }, null, 8, ["isRemoteVisible", "ranker", "ranking", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}