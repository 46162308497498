
import { defineComponent, Ref, ref, reactive, toRefs, onMounted } from "vue";
import { CaretDownOutlined } from "@ant-design/icons-vue";
import { Ranking, Ranker } from "@/models/gin";
import { apiCompany } from "@/lib/api_gin";
import { userStore } from "@/store/user";
import { useRoute } from "vue-router";
import Empty from "@/components/Empty.vue";
import RankDetail from "@/components/RankDetail.vue";
const ranges: { [key: string]: { name: string } } = {
  all: {
    name: "不限時間",
  },
  thisYear: {
    name: "今年",
  },
  thisMonth: {
    name: "本月份",
  },
  lastMonth: {
    name: "上個月",
  },
  yesterday: {
    name: "昨日",
  },
  today: {
    name: "今日",
  },
};
export default defineComponent({
  name: "ViewRank",
  components: { CaretDownOutlined, Empty, RankDetail },
  setup() {
    const route = useRoute();
    const currentScope: "agent" | "star" = route.params.scope as
      | "agent"
      | "star";
    const state = reactive({
      isReady: false,
      isDetailVisible: false,
    });
    const selectedRanking: Ref<null | Ranking> = ref(null);
    const selectedRanker: Ref<null | Ranker> = ref(null);
    const allRanking: Ref<Ranking> = ref(
      new Ranking("全部收益", currentScope, "all")
    );
    const giftRanking: Ref<Ranking> = ref(
      new Ranking("禮物收益", currentScope, "face")
    );
    const postRanking: Ref<Ranking> = ref(
      new Ranking("通告收益", currentScope, "post")
    );
    const getRankings = async (ranking?: Ranking) => {
      if (ranking) {
        if (ranking.range === "demo") {
          ranking.setRankers();
        } else {
          const rankingRes = await apiCompany.getRevenueList(
            {
              scope: route.params.scope as "agent" | "star",
              category: "all",
              ...ranking.getRange(),
            },
            userStore.ginToken.value
          );
          ranking.setRankers(rankingRes);
        }
      } else {
        const allRankingRes = await apiCompany.getRevenueList(
          {
            scope: route.params.scope as "agent" | "star",
            category: "all",
            ...allRanking.value.getRange(),
          },
          userStore.ginToken.value
        );
        const giftRankingRes = await apiCompany.getRevenueList(
          {
            scope: route.params.scope as "agent" | "star",
            category: "gift",
            ...giftRanking.value.getRange(),
          },
          userStore.ginToken.value
        );
        const postRankingRes = await apiCompany.getRevenueList(
          {
            scope: route.params.scope as "agent" | "star",
            category: "post",
            ...postRanking.value.getRange(),
          },
          userStore.ginToken.value
        );
        allRanking.value.setRankers(allRankingRes);
        giftRanking.value.setRankers(giftRankingRes);
        postRanking.value.setRankers(postRankingRes);
      }
    };
    onMounted(async () => {
      state.isReady = false;
      await getRankings();
      state.isReady = true;
    });
    const beforeShowDetail = async (rankerGinId: number): Promise<boolean> => {
      const currentUser = userStore.currentUser.value;
      const role = currentUser.role;
      const isCompany = role.isCompany();
      const isAdmin = role.isAdmin();
      const isMe = rankerGinId === currentUser.ginId;
      if (currentScope === "agent") {
        return Promise.resolve(isCompany || isAdmin || isMe);
      } else {
        const isAgent = role.isAgent();
        // let isMyFollower = false;
        // if (isAgent) {
        //   const myStarGinIds: number[] = await apiCompany
        //     .getStarList(
        //       { status: true, page: 0, limit: 0, isMine: true },
        //       userStore.ginToken.value
        //     )
        //     .then((res) => res.getDataList().map((e) => e.getId()));
        //   isMyFollower = myStarGinIds.includes(rankerGinId);
        // }
        return Promise.resolve(isCompany || isAdmin || isAgent);
      }
    };
    const showDetail = async (ranking: Ranking, ranker: Ranker) => {
      const isVisible = await beforeShowDetail(ranker.ginId);
      if (ranker.ginId > 0 && isVisible) {
        selectedRanker.value = ranker;
        selectedRanking.value = ranking;
        state.isDetailVisible = true;
      }
    };
    const closeDetail = () => {
      state.isDetailVisible = false;
    };
    return {
      ...toRefs(state),
      allRanking,
      giftRanking,
      postRanking,
      ranges,
      getRankings,
      closeDetail,
      selectedRanker,
      selectedRanking,
      showDetail,
    };
  },
});
