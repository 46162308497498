
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import Icon, { LeftOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  name: "GoBackButton",
  components: { LeftOutlined },
  props: {
    path: { type: String },
  },
  setup(props) {
    const router = useRouter();
    const goback = () => {
      if (props.path) {
        router.push({ path: props.path });
      } else {
        router.go(-1);
      }
    };
    return {
      goback,
    };
  },
});
