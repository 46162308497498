import { GinRole } from "@lctech-tw/gin-proto/dist/js/star/atom/enums_pb";
const humanRoles = new Map([
  [GinRole.NONE, ""],
  [GinRole.ADMIN, "管理員"],
  [GinRole.AGENT, "經紀"],
  [GinRole.COMPANY, "經紀公司"],
]);
class Role {
  originalRole: GinRole = GinRole.NONE;
  humanRole = "";
  setRole(ginRole: GinRole): void {
    this.originalRole = ginRole;
    this.humanRole = humanRoles.get(ginRole) || "";
  }
  isCompany(): boolean {
    return this.originalRole === GinRole.COMPANY;
  }
  isAdmin(): boolean {
    return this.originalRole === GinRole.ADMIN;
  }
  isAgent(): boolean {
    return this.originalRole === GinRole.AGENT;
  }
}
export { Role, humanRoles };
