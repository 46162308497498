
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { apiCompany } from "@/lib/api_gin";
import { userStore } from "@/store/user";
import { Toast } from "@/lib/toast";
export default defineComponent({
  name: "StarStatusChanger",
  emits: ["update"],
  props: {
    uid: { required: true, type: Number },
    disabled: { default: false, type: Boolean },
    originalStatus: { required: true, type: Boolean },
  },
  setup(props, context) {
    const state = reactive({
      objectId: "",
      status: false,
    });
    // const getStatus = async () => {
    //   state.status = await apiCompany
    //     .getStarDetail({ uid: props.uid }, userStore.ginToken.value)
    //     .then((res) => res.getData()?.getStatus() || false);
    //   return Promise.resolve(true);
    // };
    const changeStatus = async (status: boolean) => {
      if (status !== state.status) {
        try {
          const yes = confirm(`確定${status ? "啟用" : "停用"}此藝人?`);
          if (yes) {
            const res = await apiCompany.updateStarStatus(
              { uid: props.uid, status },
              userStore.ginToken.value
            );
            state.status = status;
            Toast.info("更新成功");
            context.emit("update", status);
          }
        } catch (error) {
          Toast.bugOccur();
        }
      }
    };
    onMounted(async () => {
      state.status = props.originalStatus;
      // await getStatus();
    });
    return { ...toRefs(state), ...toRefs(props), changeStatus };
  },
});
