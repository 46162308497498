
import { userStore } from "@/store/user";
import { imageStore } from "@/store/image";
import {
  defineComponent,
  Ref,
  ref,
  computed,
  reactive,
  toRefs,
  onMounted,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import RegisterFormComponent from "@/components/StarForm.vue";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { apiCompany } from "@/lib/api_gin";
import { Toast } from "@/lib/toast";
import { RegisterForm } from "@/models/register";
import GoBackButton from "@/components/GoBackButton.vue";
import { AgentUser, BaseUser, UploadFileType } from "@/models/gin";
import Loader from "@/components/Loader.vue";
import _ from "lodash";
enum FormAction {
  trash = "trash",
  approve = "approve",
  recover = "recover",
  save = "save",
}
const validationMessages = new Map([
  [FormAction.approve, "確定要通過這份資料的審查嗎？"],
  [FormAction.trash, "確定要廢案這份資料嗎？"],
  [FormAction.recover, "確定要將這份資料恢復候審嗎？"],
  [FormAction.save, "確定儲存資料嗎？"],
]);
const submitMessages = new Map([
  [FormAction.approve, "已成功過審，請前往手機查收簡訊"],
  [FormAction.trash, "已移入廢案"],
  [FormAction.recover, ""],
  [FormAction.save, "已儲存成功"],
]);
export default defineComponent({
  name: "ViewRegisterDetail",
  components: {
    RegisterFormComponent,
    CheckOutlined,
    CloseOutlined,
    GoBackButton,
    Loader,
  },
  setup() {
    const state = reactive({
      isEditing: false,
      isLoading: false,
    });
    const router = useRouter();
    const route = useRoute();
    const registerId = Number(route.params.registerId) as number;
    const ginToken = userStore.ginToken.value;
    const starObjectId = String(route.query.starObjectId) as string;
    const isMyFollower = computed(() => {
      return currentAgent.value.ginId === userStore.currentUser.value.ginId;
    });
    const isSupervisor = computed(() => {
      return (
        userStore.currentUser.value.role.isAdmin() ||
        userStore.currentUser.value.role.isCompany()
      );
    });
    const isPrivateDataVisible = computed(() => {
      const currentUser = userStore.currentUser.value;
      return (
        currentUser.role.isCompany() ||
        currentUser.role.isAdmin() ||
        (currentUser.role.isAgent() &&
          currentAgent.value.ginId === userStore.currentUser.value.ginId)
      );
    });
    const currentAction: Ref<FormAction> = ref(FormAction.approve);
    const currentForm: Ref<RegisterForm> = ref(new RegisterForm());
    const editedForm: Ref<RegisterForm> = ref(new RegisterForm());
    const currentAgent: Ref<AgentUser> = ref(new AgentUser());
    const currentLady: Ref<BaseUser> = ref(new BaseUser());

    const handleSubmit = async (assignedAction?: FormAction) => {
      state.isLoading = true;
      const action: FormAction = assignedAction || currentAction.value;

      if (action === FormAction.approve) {
        try {
          if (
            currentLady.value.avatar === imageStore.defaultAvatarImageUrl.value
          ) {
            Toast.info("請上傳新頭像");
            throw false;
          }
          await apiCompany.handleRegister(
            { registerId: registerId, action: "approve" },
            userStore.ginToken.value
          );
          router.push({ name: "registers" });
          Toast.info("已成功過審");
        } catch (error) {
          Toast.info("審核失敗，請再次確認表格內容");
        }
      } else if (action === FormAction.trash) {
        try {
          await apiCompany.handleRegister(
            { registerId: registerId, action: "trash" },
            userStore.ginToken.value
          );
          router.push({ name: "registers" });
          Toast.info(submitMessages.get(action) || "");
        } catch (error) {
          Toast.bugOccur();
        }
      } else if (action === FormAction.recover) {
        try {
          await apiCompany.handleRegister(
            { registerId: registerId, action: "recover" },
            userStore.ginToken.value
          );
          router.push({ name: "registers" });
          Toast.info(submitMessages.get(action) || "");
        } catch (error) {
          Toast.bugOccur();
        }
      } else {
        if (editedForm.value) {
          try {
            await apiCompany.saveRegisterProfile(
              {
                profile: editedForm.value.toStarProfileData(),
                privateData: editedForm.value.toStarPrivateData(),
                registerId: registerId,
              },
              userStore.ginToken.value
            );
            toggleEdit(false);
            currentForm.value = _.cloneDeep(editedForm.value);
            Toast.info(submitMessages.get(currentAction.value) || "");
          } catch (error) {
            Toast.bugOccur();
          }
        }
      }
      state.isLoading = false;
    };
    const validate = (action: FormAction, form?: RegisterForm) => {
      if (form) {
        editedForm.value = form;
      }
      const submitButton = document.querySelector(
        "#register_submit_btn"
      ) as HTMLButtonElement;

      currentAction.value = action;
      const message = validationMessages.get(action);
      const yes = confirm(message);
      if (yes) {
        submitButton.click();
      }
    };
    const toggleEdit = (bool: boolean) => {
      state.isEditing = bool;
    };

    const getMyRegisterProfile = async () => {
      const res = await apiCompany.getRegisterDetail(
        { registerId: registerId },
        userStore.ginToken.value
      );
      currentForm.value = new RegisterForm(res);
      const info = res.getData()?.getStaffInfo();
      if (info) {
        currentAgent.value = new AgentUser(info);
      }
    };

    const getCurrentLady = async () => {
      const res = await window.dog.pan.api.ReadObjectByObjectId(starObjectId);
      currentLady.value = new BaseUser(res);
      // currentLady.value.avatar = imageStore.defaultAvatarImageUrl.value;
    };

    const sendSMS = async () => {
      try {
        const yes = confirm("確定要送出簡訊？");
        if (yes) {
          const res = await apiCompany.generateRegisterCode(
            { ginId: currentAgent.value.ginId },
            userStore.ginToken.value
          );
          const url = `${window.location.origin}/new/${res.getCode()}`;
          const SMSContent = `您好，您申請的JKF女郎資料尚未完成，請點擊以下連結填寫資料 \n${url}\n 如有問題請洽經紀。
`;
          await apiCompany.sendSMS(
            {
              content: SMSContent,
              phone: currentForm.value.phone,
            },
            userStore.ginToken.value
          );
          Toast.info("已送出簡訊");
        }
      } catch (error) {
        Toast.bugOccur();
      }
    };

    onMounted(async () => {
      state.isLoading = true;
      await getMyRegisterProfile();
      await getCurrentLady();
      state.isLoading = false;
    });

    return {
      ...toRefs(state),
      registerId,
      ginToken,
      starObjectId,
      validate,
      handleSubmit,
      FormAction,
      userStore,
      toggleEdit,
      isSupervisor,
      currentForm,
      currentLady,
      currentAgent,
      isMyFollower,
      isPrivateDataVisible,
      sendSMS,
    };
  },
});
