
import { apiCompany } from "@/lib/api_gin";
import { BaseUser, GinUser } from "@/models/gin";
import { userStore } from "@/store/user";
import {
  defineComponent,
  reactive,
  Ref,
  ref,
  onMounted,
  toRefs,
  computed,
} from "vue";
import { SearchOutlined, EyeFilled } from "@ant-design/icons-vue";
import CommonCard from "@/components/CommonCard.vue";
import UserSelector from "@/components/UserSelector.vue";
import { Toast } from "@/lib/toast";
import Empty from "@/components/Empty.vue";
import Loader from "@/components/Loader.vue";
export default defineComponent({
  name: "ViewAdminList",
  components: {
    CommonCard,
    UserSelector,
    Empty,
    Loader,
    EyeFilled,
    SearchOutlined,
  },
  setup() {
    const state = reactive({
      search: "",
      status: true,
      isLoading: false,
    });
    const adminsList: Ref<GinUser[]> = ref([]);
    const isEditable = computed(() => {
      return userStore.currentUser.value.role.isCompany();
    });
    const isDisabledListVisible = computed(() => {
      const role = userStore.currentUser.value.role;
      return role.isCompany() || role.isAdmin();
    });
    const isMe = (ginId: number): boolean => {
      return ginId === userStore.currentUser.value.ginId;
    };
    const getAdmins = async () => {
      state.isLoading = true;
      const res = await apiCompany.getStaffList(
        { name: state.search, role: "admin", status: state.status },
        userStore.ginToken.value
      );
      adminsList.value = res
        .getDataList()
        .map((e) => new GinUser(e))
        .sort((a, b) => Number(isMe(b.ginId)) - Number(isMe(a.ginId)));
      state.isLoading = false;
    };

    const subMemberList: Ref<BaseUser[]> = ref([]);
    const getSubMemberList = async () => {
      const accessToken = await userStore.getAccessToken();
      if (accessToken) {
        const res = await window.dog.pan.api.OtherMember(accessToken);
        const excludes: string[] = await apiCompany
          .getStaffList({ status: true }, userStore.ginToken.value)
          .then((r) => r.getDataList().map((e) => e.getObjectId()));
        subMemberList.value = res
          .filter((e) => !e.getIsOwner() && !excludes.includes(e.getObjectId()))
          .map((e) => new BaseUser(e));
      }
    };
    const onSelect = async (payload: {
      item: BaseUser;
      close: () => void;
      toDone: () => void;
    }) => {
      try {
        const res = await apiCompany.setSubMemberAs(
          {
            objectId: payload.item.objectId,
            role: "admin",
          },
          userStore.ginToken.value
        );
        subMemberList.value = subMemberList.value.filter(
          (e) => e.objectId !== payload.item.objectId
        );
        payload.toDone();
      } catch (error) {
        Toast.bugOccur();
      }
    };
    const isAdminCreater = userStore.currentUser.value.role.isCompany();
    onMounted(() => {
      getAdmins();
      getSubMemberList();
    });
    return {
      ...toRefs(state),
      adminsList,
      getAdmins,
      onSelect,
      subMemberList,
      isAdminCreater,
      isMe,
      isEditable,
      isDisabledListVisible,
    };
  },
});
