import dayjs, { Dayjs } from "dayjs";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { strictDayjsToTimestamp } from "./utils";
declare module "dayjs" {
  interface Dayjs {
    toTimestamp(): Timestamp;
  }
}
dayjs.prototype.toTimestamp = function () {
  return strictDayjsToTimestamp(this);
};
