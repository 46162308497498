import { createApp } from "vue";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

import { userStore } from "@/store/user";
import { importPan } from "./lib/pan";
import { importDog } from "./lib/dog";

import dayjs from "dayjs";
import "dayjs/locale/zh-tw";
dayjs.locale("zh-tw");

import { useFavicon } from "@vueuse/core";
const icon = useFavicon();
icon.value = "/assets/favicons/jkf.ico";
// icon.value = "/assets/favicons/twitter.2.ico";

const start = async () => {
  await importPan();
  await importDog();
  createApp(App).use(Antd).use(router).mount("#app");
};

start();
