
import {
  defineComponent,
  reactive,
  toRefs,
  Ref,
  ref,
  onMounted,
  watch,
  computed,
} from "vue";
import { CloseOutlined } from "@ant-design/icons-vue";
import { ColumnsType } from "ant-design-vue/lib/table";
import { apiCompany } from "@/lib/api_gin";
import { userStore } from "@/store/user";
import { Ranking, Ranker } from "@/models/gin";

export default defineComponent({
  name: "RankDetail",
  components: { CloseOutlined },
  props: {
    isRemoteVisible: { required: true, type: Boolean },
    ranking: { required: true, type: Ranking },
    ranker: { required: true, type: Ranker },
  },
  emits: ["close"],
  setup(props, context) {
    const state = reactive({
      isLoading: false,
      isVisible: false,
      tableHeight: 250,
      modalWidth: 375,
    });
    const profitList: Ref<
      {
        sort: number;
        name: string;
        avatar: string;
        income: number;
      }[]
    > = ref([]);
    const columns: ColumnsType = [
      {
        title: "名次",
        dataIndex: "sort",
        key: "sort",
        width: 60,
        align: "center",
      },
      {
        title:
          props.ranking.scope === "agent" ? "旗下藝人頭像、名稱" : "付款者名稱",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "收益金額",
        dataIndex: "income",
        key: "income",
        align: "right",
      },
    ];
    const getProfitList = async (isVisible: boolean) => {
      if (isVisible) {
        const scope = props.ranking.scope;
        const rkg = props.ranking;
        const rkr = props.ranker;
        profitList.value = [];
        if (scope === "agent") {
          const res = await apiCompany.getStarFromStaffRevenueList(
            {
              page: 1,
              limit: 20,
              staffId: rkr.ginId,
              sourceType: rkg.sourceType,
              from: rkg.getRange().from,
              to: rkg.getRange().to,
            },
            userStore.ginToken.value
          );
          profitList.value = res.getDataList().map((e, index) => {
            return {
              sort: index + 1,
              name: e.getDisplayName(),
              avatar: e.getAvatarUrl(),
              income: e.getSum(),
            };
          });
          state.isVisible = true;
        } else if (scope === "star") {
          const res = await apiCompany.getStarTopFansRevenueList(
            {
              page: 1,
              limit: 20,
              starObjectId: rkr.objectId,
              sourceType: rkg.sourceType,
              from: rkg.getRange().from,
              to: rkg.getRange().to,
            },
            userStore.ginToken.value
          );
          profitList.value = res.getDataList().map((e, index) => {
            return {
              sort: index + 1,
              name: e.getDisplayName(),
              avatar: e.getAvatarUrl(),
              income: e.getSum(),
            };
          });
          if (profitList.value.length > 0) {
            state.isVisible = true;
          } else {
            close();
          }
        } else {
          profitList.value = [];
        }
      } else {
        close();
        state.isVisible = false;
      }
    };
    const close = () => {
      context.emit("close");
    };
    watch(
      () => props.isRemoteVisible,
      (val, oldVal) => {
        if (val !== oldVal) {
          getProfitList(val);
        }
      },
      { immediate: true }
    );
    const isAvatarVisible = computed(() => {
      return props.ranking.scope === "agent";
    });
    onMounted(() => {
      const vh = window.innerHeight / 100;
      const vw = window.innerWidth / 100;
      state.tableHeight = 50 * vh;
      state.modalWidth = 50 * vw;
    });
    return {
      ...toRefs(props),
      ...toRefs(state),
      columns,
      profitList,
      close,
      isAvatarVisible,
    };
  },
});
