import { Role } from "./role";

class User {
  ginId = -1;
  uid = -1;
  objectId = "-1";
  name = "-";
  avatar = "";
  role = new Role();
  setUser(payload: {
    uid: number;
    objectId: string;
    name: string;
    avatar: string;
  }): User {
    this.uid = payload.uid;
    this.objectId = payload.objectId;
    this.name = payload.name;
    this.avatar = payload.avatar;
    return this;
  }
}

export { User };
