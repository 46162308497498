
import { userStore } from "@/store/user";
import { imageStore } from "@/store/image";
import { starStore } from "@/store/star";
import { publicStore } from "@/store/public";
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted,
  onBeforeUnmount,
  computed,
  Ref,
} from "vue";
import {
  PlusOutlined,
  MinusOutlined,
  LoadingOutlined,
} from "@ant-design/icons-vue";
import {
  RegisterForm,
  BloodTypes,
  BoundaryTypes,
  humanCardName,
} from "@/models/register";
import { BaseUser, AgentUser, UploadFileType } from "@/models/gin";
import zhTW from "ant-design-vue/es/locale/zh_TW";
import type { Rule } from "ant-design-vue/es/form";
import StarCard from "@/components/StarCard.vue";
import StarFormView from "@/components/StarFormView.vue";
import _, { toArray } from "lodash";
import { FormInstance } from "ant-design-vue/lib/form/Form";
import { apiCompany } from "@/lib/api_gin";
import { Toast } from "@/lib/toast";
import { Form } from "ant-design-vue";
import { isMobile, isDesktop } from "@/lib/utils";
const useForm = Form.useForm;
export default defineComponent({
  name: "StarForm",
  components: {
    PlusOutlined,
    MinusOutlined,
    LoadingOutlined,
    StarCard,
    StarFormView,
  },
  props: {
    registerId: { default: -1, type: Number },
    isEditing: { default: false, type: Boolean },
    currentLady: { required: true, type: BaseUser },
    originalForm: { required: true, type: RegisterForm },
    agent: { required: true, type: AgentUser },
    isOnValidation: { default: true, type: Boolean },
    isPrivateDataVisible: { default: true, type: Boolean },
    isTagVisible: { default: true, type: Boolean },
    userScope: { default: "staff", type: String },
  },
  emits: {
    submit: null,
  },
  setup(props, context) {
    const formRef = ref<FormInstance>();
    const state = reactive({
      isLoading: false,
      dev: userStore.isDev,
      currentAgentName: "",
      currentAgentGinId: 0,
      isTabsStickingTop: false,
      sectionNames: [
        "基本資料",
        "照片資料",
        "社群媒體",
        "證件資料",
        "訪談資料",
        "標籤與分類",
      ],
      highlights: ["memo"],
    });
    const tagList = starStore.tagList.value;
    const categoryList = starStore.categoryList.value;
    const editForm = ref(new RegisterForm());
    const onSubmit = () => {
      context.emit("submit");
    };

    const uploadFile = {
      beforeBannerUpload: async (file: File) => {
        await beforeImageUpload(file, UploadFileType.banner);
        return false;
      },
      beforeLogoUpload: async (file: File) => {
        await beforeImageUpload(file, UploadFileType.logo);
        return false;
      },
      beforeBankUpload: async (file: File) => {
        await beforeImageUpload(file, UploadFileType.bank);
        return false;
      },
      beforeIdCardFrontUpload: async (file: File) => {
        await beforeImageUpload(file, UploadFileType.idcard, 0);
        return false;
      },
      beforeIdCardBackUpload: async (file: File) => {
        await beforeImageUpload(file, UploadFileType.idcard, 1);
        return false;
      },
      card1: async (file: File) => {
        await beforeImageUpload(file, UploadFileType.modelcard, 0);
        return false;
      },
      card2: async (file: File) => {
        await beforeImageUpload(file, UploadFileType.modelcard, 1);
        return false;
      },
      card3: async (file: File) => {
        await beforeImageUpload(file, UploadFileType.modelcard, 2);
        return false;
      },
      card4: async (file: File) => {
        await beforeImageUpload(file, UploadFileType.modelcard, 3);
        return false;
      },
    };

    const beforeImageUpload = async (
      file: File,
      type: UploadFileType,
      index?: number
    ) => {
      if (props.userScope === "staff") {
        const result = await imageStore.uploadFileByStaff(
          file,
          type,
          props.currentLady.objectId,
          userStore.ginToken.value
        );
        updateImage(result.url, result.path, type, index);
      } else {
        const result = await imageStore.uploadFileByStar(
          file,
          type,
          publicStore.accessToken.value
        );
        updateImage(result.url, result.path, type, index);
      }
    };
    const updateImage = (
      url: string,
      path: string,
      type: UploadFileType,
      index?: number
    ) => {
      if (type === UploadFileType.banner) {
        editForm.value.banner.url = url;
        editForm.value.banner.path = path;
      } else if (type === UploadFileType.bank) {
        editForm.value.bank.url = url;
        editForm.value.bank.path = path;
      } else if (type === UploadFileType.idcard && index === 0) {
        editForm.value.idCardFront.url = url;
        editForm.value.idCardFront.path = path;
      } else if (type === UploadFileType.idcard && index === 1) {
        editForm.value.idCardBack.url = url;
        editForm.value.idCardBack.path = path;
      } else if (type === UploadFileType.logo) {
        editForm.value.logo.url = url;
        editForm.value.logo.path = path;
      } else if (type === UploadFileType.modelcard && index === 0) {
        editForm.value.modelCard.card1.url = url;
        editForm.value.modelCard.card1.path = path;
      } else if (type === UploadFileType.modelcard && index === 1) {
        editForm.value.modelCard.card2.url = url;
        editForm.value.modelCard.card2.path = path;
      } else if (type === UploadFileType.modelcard && index === 2) {
        editForm.value.modelCard.card3.url = url;
        editForm.value.modelCard.card3.path = path;
      } else if (type === UploadFileType.modelcard && index === 3) {
        editForm.value.modelCard.card4.url = url;
        editForm.value.modelCard.card4.path = path;
      }
    };
    const onScroll = () => {
      const rect = document
        .querySelector("#myform-tabs-pivot")
        ?.getBoundingClientRect() as DOMRect;
      const stickingTab = document.querySelector(
        ".myform-tabs.myform-tabs--stick"
      ) as HTMLDivElement;
      const isInBound = rect.top < 5;
      if (isInBound) {
        stickingTab.style.display = "inline-flex";
        stickingTab.style.width = `${rect.width}px`;
      } else {
        stickingTab.style.display = "none";
      }
      const sectionNames = state.sectionNames;
      const sections = document.querySelectorAll(
        sectionNames.map((e) => `#${tabPrefix.value + e}`).join(", ")
      ) as NodeList;
      const mostRecentSection = Array.from(sections).reduce(function (
        prev,
        curr
      ) {
        const offsetHeight =
          (stickingTab.getBoundingClientRect()?.height || 0) + 15;
        let prevDOM = prev as HTMLElement;
        let currDOM = curr as HTMLElement;
        const prevDistance = prevDOM.getBoundingClientRect().top - offsetHeight;
        const currDistance = currDOM.getBoundingClientRect().top - offsetHeight;
        return currDistance < 0 &&
          Math.abs(currDistance) < Math.abs(prevDistance)
          ? currDOM
          : prevDOM;
      });
      const tabs = document.querySelectorAll(".myform-tabs-item");
      Array.from(tabs).map((e) => {
        const tabDOM = e as HTMLElement;
        const sectionDOM = mostRecentSection as HTMLElement;
        if (
          sectionDOM.getAttribute("id") ===
          tabDOM.getAttribute("href")?.replace("#", "")
        ) {
          tabDOM.classList.add("myform-tabs-item--selected");
        } else {
          tabDOM.classList.remove("myform-tabs-item--selected");
        }
        return e;
      });
    };
    onMounted(async () => {
      state.isLoading = true;
      editForm.value = _.cloneDeep(props.originalForm);
      try {
        console.log(
          props.originalForm.memo,
          JSON.parse(props.originalForm.memo),
          state.highlights
        );
        const parsedMemo: { [key: number]: string } = JSON.parse(
          props.originalForm.memo
        );
        state.highlights = Object.values(parsedMemo);
      } catch (error) {
        console.log("螢光筆異常", error);
      }
      [
        editForm.value.instagramList,
        editForm.value.facebookList,
        editForm.value.twitterList,
        editForm.value.tiktokList,
      ].map((e) => {
        if (e.snsUrls.length === 0) {
          e.add();
        }
      });
      state.currentAgentGinId = props.agent.ginId;
      state.currentAgentName = props.agent.name;
      document.addEventListener("scroll", _.throttle(onScroll, 250));
      state.isLoading = false;
    });
    onBeforeUnmount(() => {
      document.removeEventListener("scroll", _.throttle(onScroll, 250));
    });

    const validateThreeSize = async (_rule: Rule, value: string) => {
      if (props.isOnValidation) {
        if (
          editForm.value.bust &&
          editForm.value.waist &&
          editForm.value.hips
        ) {
          return Promise.resolve();
        } else {
          return Promise.reject("三圍 是必填資料!");
        }
      } else {
        return Promise.resolve();
      }
    };

    const validateMessages = {
      required: "${label} 是必填資料",
    };
    const tabPrefix = computed(() => {
      return props.isEditing ? "" : "_";
    });

    const setCategory = (id: number) => {
      editForm.value.category = id;
    };

    const setTag = (id: number) => {
      const foundIndex = editForm.value.tagList.findIndex((e) => e === id);
      if (id === 0) {
        editForm.value.tagList = [0];
      } else {
        if (foundIndex >= 0) {
          editForm.value.tagList.splice(foundIndex, 1);
        } else {
          editForm.value.tagList.push(id);
          editForm.value.tagList = editForm.value.tagList.filter((e) => e > 0);
        }
      }
    };

    const move = (ev: MouseEvent) => {
      const tab = ev.target as HTMLElement;
      const query = tab.getAttribute("href") as string;
      const target = document.querySelector(query) as HTMLElement;
      const positionY = target.offsetTop;

      if (isMobile()) {
        window.scrollTo(0, positionY - 72);
      } else {
        window.scrollTo(0, positionY + 5);
      }
    };

    const highlight = async (label: string) => {
      console.log("highlight...: ", label);

      let highlights = state.highlights;
      const index = state.highlights.indexOf(label);
      if (index > 0) {
        highlights.splice(index, 1);
      } else {
        highlights.push(label);
      }
      if (highlights.length === 0) {
        highlights.push("memo");
      }
      const memo = JSON.stringify(Object.assign({}, highlights));

      try {
        const res = await apiCompany.updateMemo(
          { registerId: props.registerId, memo: memo },
          userStore.ginToken.value
        );
      } catch (error) {
        Toast.bugOccur();
      }
    };

    const logoSamples = imageStore.logoSamples;

    const cardName = (key: string): string => {
      return humanCardName.get(key) || "-";
    };

    const rulesRef = reactive({
      bust: [
        {
          required: props.isOnValidation,
          validator: validateThreeSize,
        },
      ],
    });
    const { resetFields, validate, validateInfos, mergeValidateInfo } = useForm(
      editForm.value,
      rulesRef
    );
    const errorInfos = computed(() => {
      const result = mergeValidateInfo(toArray(validateInfos));
      return result;
    });

    return {
      ...toRefs(state),
      ...toRefs(props),
      editForm,
      onSubmit,
      validateThreeSize,
      validateMessages,
      zhTW,
      BloodTypes,
      BoundaryTypes,
      uploadFile,
      tabPrefix,
      tagList,
      categoryList,
      setCategory,
      setTag,
      move,
      logoSamples,
      highlight,
      cardName,
      validate,
      errorInfos,
      isMobile,
    };
  },
});
