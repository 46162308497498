import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d490ab08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user" }
const _hoisted_2 = { class: "avatar g-avatar-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "role" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.currentUser.avatar,
        alt: ""
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.currentUser.name), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.currentUser.role.humanRole), 1)
  ]))
}