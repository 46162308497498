
import { userStore } from "@/store/user";
import { defineComponent, reactive, Ref, toRefs, ref, onMounted } from "vue";
import { CloseOutlined, FileDoneOutlined } from "@ant-design/icons-vue";
import _ from "lodash";
import { apiMember } from "@/lib/api_member";
import { CompanyForm } from "@/models/gin";
import StaffCard from "@/components/StaffCard.vue";
import { Toast } from "@/lib/toast";
export default defineComponent({
  name: "ViewHome",
  components: {
    CloseOutlined,
    StaffCard,
  },
  setup() {
    const state = reactive({
      isReady: false,
      isEditingCompany: false,
      isComanyEditable: userStore.currentUser.value.role.isCompany(),
      isMyCardVisible: !userStore.currentUser.value.role.isCompany(),
      ginId: userStore.currentUser.value.ginId,
    });
    const companyInfo = ref(new CompanyForm());
    const companyForm = ref(new CompanyForm());

    const getMyCompany = async () => {
      const res = await apiMember.getMyCompany(userStore.ginToken.value);
      companyInfo.value = new CompanyForm(res);
      companyForm.value = new CompanyForm(res);
    };
    const edit = () => {
      state.isEditingCompany = true;
    };
    const cancel = () => {
      state.isEditingCompany = false;
      companyForm.value = _.cloneDeep(companyInfo.value);
    };
    const update = async () => {
      const yes = confirm("確定修改資料嗎？");
      if (yes) {
        try {
          const isUpdated = await apiMember.updateMemberInfo(
            {
              email: companyForm.value.email.value,
            },
            userStore.ginToken.value
          );
          state.isEditingCompany = false;
          if (isUpdated) {
            companyInfo.value = _.cloneDeep(companyForm.value);
          }
          Toast.info("資料已儲存");
        } catch (error) {
          Toast.bugOccur();
        }
      }
    };
    const beforeAvatarUpload = async (file: File) => {
      const res = await apiMember.generateAvatarUploadUrl(
        userStore.ginToken.value
      );

      const fd = new FormData();
      const signMap = res.getSignedPostPolicyMap();
      signMap.forEach((value, key) => {
        fd.append(key, value);
      });
      fd.append("file", file);
      return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();

        request.open("POST", res.getUrlToUpload());
        // request.upload.addEventListener("progress", (e) => {});

        request.addEventListener("readystatechange", async (ev) => {
          if (
            request.readyState === XMLHttpRequest.DONE &&
            request.status === 200
          ) {
            state.isEditingCompany = false;
            getMyCompany();
            resolve(true);
          }
        });
        request.send(fd);
      });
    };
    onMounted(async () => {
      state.isReady = false;
      await getMyCompany();
      state.isReady = true;
    });
    const version = ref(process.env.VUE_APP_VERSION);
    return {
      ...toRefs(state),
      companyInfo,
      companyForm,
      edit,
      cancel,
      update,
      beforeAvatarUpload,
      version,
    };
  },
});
