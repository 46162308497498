
import { showPan } from "@/lib/pan";
import { defineComponent, onMounted, reactive, toRefs, ref, Ref } from "vue";
import { useRoute } from "vue-router";
import RegisterFormComponent from "@/components/StarForm.vue";
import { apiStar } from "@/lib/api_star";
import { userStore } from "@/store/user";
import { publicStore } from "@/store/public";
import { RegisterForm } from "@/models/register";
import { Toast } from "@/lib/toast";
import { AgentUser, BaseUser } from "@/models/gin";
export default defineComponent({
  name: "ViewRegister",
  components: { RegisterFormComponent },
  setup() {
    const route = useRoute();
    const state = reactive({
      isReady: false,
      isExpired: false,
      isLoading: false,
      isFirstTime: true,
      isDev: userStore.isDev,
    });
    const currentForm: Ref<RegisterForm> = ref(new RegisterForm());
    const currentAgent: Ref<AgentUser> = ref(new AgentUser());
    const currentLady: Ref<BaseUser> = ref(new BaseUser());
    const login = () => {
      showPan(async (tokens) => {
        publicStore.init(tokens.auth, tokens.access);
        await init();
      });
    };

    const validate = (form: RegisterForm) => {
      currentForm.value = form;
      const submitButton = document.querySelector(
        "#register_submit_btn"
      ) as HTMLButtonElement;
      submitButton.click();
    };

    const getMyRegisterProfile = async () => {
      const code = route.params.code as string;
      const res = await apiStar.getMyRegisterProfile(
        { code: code },
        publicStore.accessToken.value
      );
      if (
        Object.values(res.toObject()).filter((e) => e !== undefined).length > 0
      ) {
        state.isFirstTime = false;
      } else {
        state.isFirstTime = true;
      }
      currentForm.value = new RegisterForm(res);
    };

    const getCurrentLady = async () => {
      const res = await window.dog.pan.api.GetMe(publicStore.accessToken.value);
      const data = res.getUser();
      if (data) {
        currentLady.value = new BaseUser(data);
      }
    };

    const checkExpiration = async () => {
      const code = route.params.code as string;
      try {
        currentAgent.value.name = await apiStar
          .checkVerifyCode(code)
          .then((res) => res.getStaffInfo()?.getDisplayName() || "");
        return Promise.resolve(true);
      } catch (error) {
        state.isExpired = true;
        return Promise.resolve(false);
      }
    };
    const handleSubmit = async () => {
      const code = route.params.code as string;
      if (currentForm.value) {
        await apiStar.saveProfile(
          {
            code: code,
            profile: currentForm.value.toStarProfileData(),
            privateData: currentForm.value.toStarPrivateData(),
          },
          publicStore.accessToken.value
        );
      }
      if (state.isFirstTime) {
        Toast.info("資料已送出");
      } else {
        Toast.info("資料已更新");
      }
    };

    onMounted(async () => {
      await init();
    });
    const init = async () => {
      state.isReady = false;

      const isCodeValid = await checkExpiration();
      if (isCodeValid) {
        if (publicStore.isLogin.value) {
          await getCurrentLady();
          await getMyRegisterProfile();
          currentForm.value.stageName = currentLady.value.name;
          currentForm.value.phone = currentLady.value.phone;
        } else {
          login();
        }
      }
      state.isReady = true;
    };
    const fakeLogin = () => {
      publicStore.fakeLogin(() => {
        init();
      });
    };
    const isLogin = publicStore.isLogin;
    return {
      login,
      ...toRefs(state),
      validate,
      fakeLogin,
      currentForm,
      currentLady,
      currentAgent,
      handleSubmit,
      isLogin,
    };
  },
});
