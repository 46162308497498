import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fc3133d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail" }
const _hoisted_2 = { class: "detail-head" }
const _hoisted_3 = { class: "detail-body" }
const _hoisted_4 = {
  key: 0,
  class: "sort"
}
const _hoisted_5 = {
  key: 1,
  class: "user"
}
const _hoisted_6 = {
  key: 0,
  class: "user-avatar g-avatar-container"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 2,
  class: "income dollar-prefix"
}
const _hoisted_9 = { class: "detail-foot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_CloseOutlined = _resolveComponent("CloseOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isVisible) = $event)),
    title: null,
    footer: null,
    closable: false,
    afterClose: _ctx.close,
    width: _ctx.modalWidth
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.ranker.name) + " " + _toDisplayString(_ctx.ranking.title) + " 明細 (" + _toDisplayString(_ctx.ranking.getRangeName()) + ") ", 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_table, {
            columns: _ctx.columns,
            "data-source": _ctx.profitList,
            loading: _ctx.isLoading,
            pagination: false,
            scroll: { y: _ctx.tableHeight }
          }, {
            bodyCell: _withCtx(({ column, text, record }) => [
              (column.dataIndex === 'sort')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(text), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'name')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_ctx.isAvatarVisible)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("img", {
                            src: record.avatar
                          }, null, 8, _hoisted_7)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", null, _toDisplayString(text), 1)
                  ]))
                : _createCommentVNode("", true),
              (column.dataIndex === 'income')
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(text.toLocaleString()), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["columns", "data-source", "loading", "scroll"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_a_button, {
            type: "primary",
            shape: "round",
            ghost: "",
            style: {"width":"225px"},
            onClick: _ctx.close
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CloseOutlined),
              _createTextVNode(" 關閉")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "afterClose", "width"]))
}