import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-da2f5cb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "page"
}
const _hoisted_2 = { class: "page-head" }
const _hoisted_3 = { class: "page-body" }
const _hoisted_4 = { class: "cards_container" }
const _hoisted_5 = { class: "modal-foot" }
const _hoisted_6 = {
  key: 1,
  class: "page"
}
const _hoisted_7 = { class: "page-body" }
const _hoisted_8 = { class: "modal-foot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_CommonCard = _resolveComponent("CommonCard")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_check_outlined = _resolveComponent("check-outlined")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_button, {
      type: "text",
      class: "show_modal_btn",
      onClick: _ctx.showModal
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_plus_outlined, { style: { 'font-size': '18px' } })
      ]),
      default: _withCtx(() => [
        _createTextVNode(" " + _toDisplayString(_ctx.btnText), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_a_modal, {
      class: _normalizeClass(["user_selector_modal", { 'user_selector_modal--select': _ctx.step === _ctx.Step.select }]),
      visible: _ctx.isVisible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isVisible) = $event)),
      title: null,
      footer: null,
      closable: false,
      width: _ctx.step === _ctx.Step.select ? '800px' : '520px'
    }, {
      default: _withCtx(() => [
        (_ctx.step === _ctx.Step.select && _ctx.isVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.memberList, (item, index) => {
                    return (_openBlock(), _createBlock(_component_CommonCard, _mergeProps(item.toCommonCardProps(), {
                      "is-foot-visible": false,
                      "is-selectable": true,
                      "is-selected": _ctx.selectedObjectId === item.objectId,
                      "is-status-visible": false,
                      onOnSelect: _ctx.onSelect,
                      key: index
                    }), null, 16, ["is-selected", "onOnSelect"]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_a_button, {
                  type: "primary",
                  ghost: "",
                  shape: "round",
                  onClick: _ctx.cancel
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_close_outlined)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" 取消")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_a_button, {
                  type: "primary",
                  shape: "round",
                  onClick: _ctx.confirm,
                  disabled: _ctx.selectedObjectId === ''
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_check_outlined)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" 確定")
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.step === _ctx.Step.done && _ctx.isVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _renderSlot(_ctx.$slots, "doneBody", {}, undefined, true)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _renderSlot(_ctx.$slots, "doneFoot", {
                  keepGoing: _ctx.keepGoing,
                  cancel: _ctx.cancel
                }, undefined, true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["class", "visible", "width"])
  ], 64))
}