import { UploadFileRes } from "@lctech-tw/gin-proto/dist/js/star/gin/gin_pb";
import { UploadAvatarRes } from "@lctech-tw/gin-proto/dist/js/star/member/member_pb";

const uploadFile = async (
  res: UploadFileRes | UploadAvatarRes,
  file: File,
  onSucess: () => void
): Promise<boolean> => {
  const fd = new FormData();
  const signMap = res.getSignedPostPolicyMap();
  signMap.forEach((value, key) => {
    fd.append(key, value);
  });
  fd.append("file", file);
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();

    request.open("POST", res.getUrlToUpload());
    request.addEventListener("readystatechange", async (ev) => {
      if (
        request.readyState === XMLHttpRequest.DONE &&
        request.status === 200
      ) {
        onSucess();
        resolve(true);
      }
    });
    request.send(fd);
  });
};

export { uploadFile };
