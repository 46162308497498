
import { PAN_CONTAINER_ID } from "@/lib/pan";
import router from "@/router";
import { userStore } from "@/store/user";
import { defineComponent, onMounted } from "vue";
import { isDesktop } from "@/lib/utils";
export default defineComponent({
  name: "ViewLogin",
  setup() {
    const isLogin = userStore.isLogin;
    const panContainerId = PAN_CONTAINER_ID;
    const login = () => {
      userStore.login(() => {
        if (isDesktop()) {
          router.push({ name: "home" });
        } else {
          router.push({ name: "posts" });
        }
      });
    };
    onMounted(() => {
      if (!isLogin.value) {
        login();
      }
    });
    return {
      login,
      panContainerId,
    };
  },
});
