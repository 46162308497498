
import { defineComponent } from "vue";
import { LoadingOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  name: "TableLoader",
  components: { LoadingOutlined },
  setup() {
    return {};
  },
});
