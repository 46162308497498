
import { apiCompany } from "@/lib/api_gin";
import { userStore } from "@/store/user";
import { defineComponent, ref, onMounted, reactive, toRefs, Ref } from "vue";
import { RegisterUser } from "@/models/gin";
import CommonCard from "@/components/CommonCard.vue";
import Icon, { SearchOutlined, EyeFilled } from "@ant-design/icons-vue";
import Empty from "@/components/Empty.vue";
import Loader from "@/components/Loader.vue";
export default defineComponent({
  name: "ViewIssueList",
  components: { CommonCard, SearchOutlined, EyeFilled, Empty, Loader },
  setup() {
    const __state: {
      search: string;
      scope: "wait" | "trash";
      isLoading: boolean;
    } = { search: "", scope: "wait", isLoading: false };
    const state = reactive(__state);
    const issueList: Ref<RegisterUser[]> = ref([]);
    const getRegisterList = async () => {
      state.isLoading = true;
      const res = await apiCompany.getRegisterList(
        {
          name: state.search,
          status: state.scope,
        },
        userStore.ginToken.value
      );
      issueList.value = res.getDataList().map((e) => new RegisterUser(e));
      state.isLoading = false;
    };
    const setScope = (scope: "wait" | "trash") => {
      state.scope = scope;
      getRegisterList();
    };
    onMounted(() => {
      getRegisterList();
    });
    return { issueList, getRegisterList, ...toRefs(state), setScope };
  },
});
