import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3723e174"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "status_changer" }
const _hoisted_2 = {
  key: 0,
  class: "card"
}
const _hoisted_3 = { class: "card-pre" }
const _hoisted_4 = {
  key: 1,
  class: "card"
}
const _hoisted_5 = { class: "card-pre" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["card_status card_status--disabled", {
            'card_status--active': !_ctx.status,
          }])
            }, " 停用 ", 2),
            _createElementVNode("div", {
              class: _normalizeClass(["card_status card_status--disabled", {
            'card_status--active': _ctx.status,
          }])
            }, " 啟用 ", 2)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: _normalizeClass(["card_status", {
            'card_status--active': !_ctx.status,
          }]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeStatus(false)))
            }, " 停用 ", 2),
            _createElementVNode("div", {
              class: _normalizeClass(["card_status", {
            'card_status--active': _ctx.status,
          }]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeStatus(true)))
            }, " 啟用 ", 2)
          ])
        ]))
  ]))
}