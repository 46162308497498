import Noty from "noty";
const theme = "gin";

const info = (message: string): void => {
  new Noty({
    text: message,
    timeout: 5000,
    theme: theme,
    layout: "center",
  }).show();
};

const bugOccur = (): void => {
  info("發生錯誤，請聯絡客服");
};
export const Toast = { info, bugOccur };
