
import { apiCompany } from "@/lib/api_gin";
import { GinUser } from "@/models/gin";
import { userStore } from "@/store/user";
import { defineComponent, reactive, Ref, ref, onMounted, toRefs } from "vue";
import CommonCard from "@/components/CommonCard.vue";
import UserSelector from "@/components/UserSelector.vue";
import Icon, {
  SearchOutlined,
  CopyOutlined,
  EyeFilled,
} from "@ant-design/icons-vue";
import { copyTextToClipboard } from "@/lib/utils";
import { Toast } from "@/lib/toast";
import Empty from "@/components/Empty.vue";
import Loader from "@/components/Loader.vue";
const searchScopes = {
  藝人名稱: "name",
  UID: "uid",
  手機號碼: "phone",
};
export default defineComponent({
  name: "ViewStarList",
  components: {
    CommonCard,
    UserSelector,
    SearchOutlined,
    CopyOutlined,
    EyeFilled,
    Empty,
    Loader,
  },
  setup() {
    const state = reactive({
      searchScope: "name",
      search: "",
      status: true,
      page: 1,
      limit: 25,
      total: 0,
      isLoading: false,
      isMine: true,
    });
    const modalState = reactive({
      isVisible: false,
      url: "",
    });

    const isAgent = userStore.currentUser.value.role.isAgent();
    const starList: Ref<GinUser[]> = ref([]);
    const getStarList = async (page: number) => {
      state.isLoading = true;
      let searchScopeParams: {
        name: string | undefined;
        uid: number | undefined;
        phone: string | undefined;
      } = {
        name: undefined,
        uid: undefined,
        phone: undefined,
      };
      if (state.searchScope === "name") {
        searchScopeParams.name = state.search;
      } else if (state.searchScope === "uid") {
        searchScopeParams.uid = Number(state.search);
      } else if (state.searchScope === "phone") {
        searchScopeParams.phone = state.search;
      }
      const res = await apiCompany.getStarList(
        {
          page: page,
          limit: state.limit,
          status: state.status,
          isMine: isAgent ? state.isMine : false,
          ...searchScopeParams,
        },
        userStore.ginToken.value
      );
      state.page = page;
      starList.value = res.getDataList().map((e) => new GinUser(e));
      state.total = res.getTotal();
      state.isLoading = false;
    };
    const onSelect = async (payload: { item: GinUser; close: () => void }) => {
      try {
        const res = await apiCompany.generateRegisterCode(
          { ginId: payload.item.ginId },
          userStore.ginToken.value
        );
        modalState.url = `${window.location.origin}/new/${res.getCode()}`;
        modalState.isVisible = true;
        payload.close();
      } catch (error) {
        Toast.bugOccur();
      }
    };
    const copyUrl = () => {
      copyTextToClipboard(modalState.url);
      Toast.info("連結已複製");
    };
    const agentList: Ref<GinUser[]> = ref([]);
    const getAgentList = async () => {
      const res = await apiCompany.getStaffList(
        {
          role: "agent",
          status: true,
        },
        userStore.ginToken.value
      );
      agentList.value = res.getDataList().map((e) => new GinUser(e));
    };
    const setIsMine = (bool: boolean) => {
      if (bool != state.isMine) {
        state.isMine = bool;
        getStarList(1);
      }
    };
    onMounted(() => {
      getStarList(1);
      getAgentList();
    });
    return {
      ...toRefs(state),
      ...toRefs(modalState),
      starList,
      getStarList,
      onSelect,
      copyUrl,
      agentList,
      isAgent,
      setIsMine,
      searchScopes,
    };
  },
});
