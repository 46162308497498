
import { userStore } from "@/store/user";
import { imageStore } from "@/store/image";
import {
  defineComponent,
  reactive,
  Ref,
  toRefs,
  ref,
  onMounted,
  computed,
} from "vue";
import { CloseOutlined, FileDoneOutlined } from "@ant-design/icons-vue";
import _ from "lodash";
import { apiMember } from "@/lib/api_member";
import { StaffForm, GinUser } from "@/models/gin";
import { apiCompany } from "@/lib/api_gin";
import { Toast } from "@/lib/toast";
import { Role } from "@/models/role";
export default defineComponent({
  name: "StaffCard",
  components: { CloseOutlined },
  props: {
    ginId: { required: true, type: Number },
    isDefaultEditing: { default: false, type: Boolean },
    isStatusEditable: { default: true, type: Boolean },
  },
  setup(props) {
    const state = reactive({
      isLoading: false,
      isEditing: false,
    });
    const staffInfo = ref(new StaffForm());
    const staffInfoForm = ref(new StaffForm());
    const currentStaffRole = ref(new Role());
    const currentStaff = ref(new GinUser());
    const isMe = computed(() => {
      return currentStaff.value.ginId === userStore.currentUser.value.ginId;
    });
    const isEditable = computed(() => {
      const currentUserRole = userStore.currentUser.value.role;
      return (
        isMe.value ||
        currentUserRole.isCompany() ||
        (currentUserRole.isAdmin() && currentStaffRole.value.isAgent())
      );
    });
    const getInfo = async () => {
      state.isLoading = true;

      const res = await apiCompany.getStaffDetail(
        { ginId: props.ginId },
        userStore.ginToken.value
      );
      const info = res.getData();
      if (info) {
        staffInfo.value = new StaffForm(info);
        staffInfoForm.value = new StaffForm(info);
        currentStaff.value = new GinUser(info);
        currentStaffRole.value.setRole(info.getRole());
      }
      state.isLoading = false;
    };
    const edit = () => {
      state.isEditing = true;
    };
    const update = async () => {
      try {
        const res = await apiCompany.updateGinUser(
          {
            objectId: currentStaff.value.objectId,
            status: staffInfoForm.value.status,
          },
          userStore.ginToken.value
        );
        state.isEditing = false;
        staffInfo.value = _.cloneDeep(staffInfoForm.value);
        Toast.info("資料更新成功");
      } catch (error) {
        Toast.bugOccur();
      }
    };
    const cancel = () => {
      state.isEditing = false;
      staffInfoForm.value = _.cloneDeep(staffInfo.value);
    };
    const beforeAvatarUpload = async (file: File) => {
      await imageStore.uploadAvatarByStaff(
        { file: file, objectId: currentStaff.value.objectId },
        userStore.ginToken.value
      );
      Toast.info("頭像更新成功");
      state.isEditing = false;
      await getInfo();
      await userStore.initUser();
    };
    onMounted(async () => {
      await getInfo();
      if (props.isDefaultEditing) {
        edit();
      }
    });
    return {
      ...toRefs(state),
      ...toRefs(props),
      staffInfo,
      staffInfoForm,
      edit,
      update,
      cancel,
      beforeAvatarUpload,
      isEditable,
      isMe,
    };
  },
});
