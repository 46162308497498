import Cookies from "js-cookie";
import { Ref, ref } from "vue";
import { authTokenKeyname, showPan } from "@/lib/pan";
import { User } from "@/models/user";

const isLogin = ref(false);
const accessToken = ref("");
const currentUser: Ref<User> = ref(new User());
let timer: number;

const getAccessToken = async (): Promise<string | null> => {
  const authToken = Cookies.get(authTokenKeyname);
  if (authToken) {
    return await window.dog.pan.api
      .AccessToken(authToken)
      .then((res) => res.getAccessToken());
  } else {
    return null;
  }
};

const fetchCurrentUser = async (panAccessToken: string): Promise<User> => {
  return window.dog.pan.api
    .GetMe(panAccessToken)
    .then((res) => res.getUser())
    .then((u) => {
      if (u) {
        return currentUser.value.setUser({
          uid: u.getUid(),
          objectId: u.getObjectId(),
          name: u.getDisplayName(),
          avatar: u.getAvatar(),
        });
      } else {
        return new User();
      }
    });
};
const initUser = async (): Promise<boolean> => {
  try {
    const currentAccessToken = await getAccessToken();
    if (currentAccessToken) {
      accessToken.value = currentAccessToken;
      currentUser.value = await fetchCurrentUser(currentAccessToken);
      isLogin.value = true;
      startTokenTicker();
    } else {
      isLogin.value = false;
      currentUser.value = new User();
    }
  } catch (error) {
    isLogin.value = false;
    currentUser.value = new User();
  }
  return isLogin.value;
};

const refreshToken = async (): Promise<boolean> => {
  try {
    const currentAccessToken = await getAccessToken();
    if (currentAccessToken) {
      accessToken.value = currentAccessToken;
      isLogin.value = true;
      return true;
    } else {
      isLogin.value = false;
      return false;
    }
  } catch (error) {
    isLogin.value = false;
    return false;
  }
};

const startTokenTicker = () => {
  const checkAuth = async () => {
    const result = await refreshToken();
    if (result === false) {
      clearInterval(timer);
      clearLoginSession();
    }
  };

  timer = window.setInterval(checkAuth, 500000);
  document.removeEventListener("visibilitychange", checkAuth);
  document.addEventListener("visibilitychange", checkAuth);
};

const login = (callback?: () => void): void => {
  showPan(async (tokens) => {
    Cookies.set(authTokenKeyname, tokens.auth, { expires: 7 });
    await initUser();
    callback && callback();
  });
};

const clearLoginSession = (): void => {
  Cookies.remove(authTokenKeyname);
  currentUser.value = new User();
  isLogin.value = false;
  accessToken.value = "";
  clearInterval(timer);
};

export const clientStore = {
  isLogin,
  currentUser,
  login,
  clearLoginSession,
  initUser,
  getAccessToken,
  accessToken,
};
