import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5127e9e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "page-head" }
const _hoisted_3 = { class: "page-head-search" }
const _hoisted_4 = {
  key: 0,
  style: {"height":"100%"}
}
const _hoisted_5 = {
  key: 1,
  class: "page-body"
}
const _hoisted_6 = {
  key: 0,
  class: "gin_tag"
}
const _hoisted_7 = {
  key: 1,
  class: "gin_tag gin_tag--light_grey"
}
const _hoisted_8 = { class: "group_btns" }
const _hoisted_9 = {
  key: 2,
  style: {"height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_eye_filled = _resolveComponent("eye-filled")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_CommonCard = _resolveComponent("CommonCard")!
  const _component_Empty = _resolveComponent("Empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_input, {
          value: _ctx.search,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
          placeholder: "搜尋待審人員名稱",
          onPressEnter: _ctx.getRegisterList
        }, {
          prefix: _withCtx(() => [
            _createVNode(_component_search_outlined, { onClick: _ctx.getRegisterList }, null, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["value", "onPressEnter"])
      ]),
      _createElementVNode("button", {
        class: _normalizeClass(["gin_button", { 'gin_button--primary': _ctx.scope === 'wait' }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setScope('wait')))
      }, " 待審 ", 2),
      _createElementVNode("button", {
        class: _normalizeClass(["gin_button", { 'gin_button--primary': _ctx.scope === 'trash' }]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setScope('trash')))
      }, " 廢案 ", 2)
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Loader)
        ]))
      : (_ctx.issueList.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.issueList, (item, index) => {
              return (_openBlock(), _createBlock(_component_CommonCard, _mergeProps(item.toCommonCardProps(), {
                "is-status-visible": true,
                key: index
              }), {
                status: _withCtx(() => [
                  (_ctx.scope === 'wait')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, "待審"))
                    : (_ctx.scope === 'trash')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, " 廢案 "))
                      : _createCommentVNode("", true)
                ]),
                foot: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      shape: "round"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: {
                  name: 'registerDetail',
                  params: { registerId: item.registerId },
                  query: { starObjectId: item.objectId },
                }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_eye_filled),
                            _createTextVNode(" 審核資料 ")
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                _: 2
              }, 1040))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_Empty)
          ]))
  ]))
}