import { Ref, ref } from "vue";

const isLogin = ref(false);
const fakeAuthToken =
  "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdXRoX21ldGhvZCI6ImF1dGgtYnktcGhvbmUtc21zIiwiZXhwaXJlIjoxOTg1MTM3MDkyLCJvYmplY3RfaWQiOiI4YzNjOGM2Mi03YTE5LTQxMGEtOTVlNC0yMWEzNzY1YzZhNDEiLCJ0b2tlbjEiOiI3ZjJkNjU3OS1iNGI1LTQ5YTgtOWJmMS1hMGJlN2FhNGM1N2MiLCJ0b2tlbjIiOiI3YTZkYzM2ZC1jMzcyLTQyNjAtOGRkMC0zODg2OGU5NDY0MmIiLCJ1aWQiOjU2NDAyNjR9.OTzjgkDJ97zKjs6wLKA9ZHG8aon1l8kgt3B3PLCofHZ23WXa4azd2QSDk2HUh6vI8NcTglPaxcQ6BG_TZwNNkw";
const authToken: Ref<string> = ref("");
const accessToken: Ref<string> = ref("");
let timer: number;

const getAccessToken = async (auth?: string): Promise<string | null> => {
  if (auth || authToken.value) {
    return await window.dog.pan.api
      .AccessToken(auth || authToken.value)
      .then((res) => res.getAccessToken());
  } else {
    return null;
  }
};

const refreshToken = async (): Promise<boolean> => {
  try {
    const currentAccessToken = await getAccessToken();
    if (currentAccessToken) {
      accessToken.value = currentAccessToken;
      isLogin.value = true;
      return true;
    } else {
      isLogin.value = false;
      return false;
    }
  } catch (error) {
    isLogin.value = false;
    return false;
  }
};

const startTokenTicker = () => {
  const checkAuth = async () => {
    const result = await refreshToken();
    if (result === false) {
      clearInterval(timer);
      clearLoginSession();
    }
  };

  timer = window.setInterval(checkAuth, 600000);
  document.removeEventListener("visibilitychange", checkAuth);
  document.addEventListener("visibilitychange", checkAuth);
};

const clearLoginSession = (): void => {
  accessToken.value = "";
  isLogin.value = false;
  clearInterval(timer);
};

const init = (auth: string, access: string): void => {
  authToken.value = auth;
  accessToken.value = access;
  isLogin.value = true;
  startTokenTicker();
};

const fakeLogin = async (callback: () => void) => {
  const access = await getAccessToken(fakeAuthToken);
  if (access) {
    init(fakeAuthToken, access);
    callback();
  }
};

export const publicStore = {
  authToken,
  accessToken,
  startTokenTicker,
  isLogin,
  init,
  fakeLogin,
};
