import { MemberServicePromiseClient } from "@lctech-tw/gin-proto/dist/js/star/member/member_grpc_web_pb";
import * as memberPb from "@lctech-tw/gin-proto/dist/js/star/member/member_pb";

const service = () => {
  const client = new MemberServicePromiseClient(
    process.env.VUE_APP_STAR_HOST || ""
  );
  const enableDevTools =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__GRPCWEB_DEVTOOLS__ ||
    (() => {
      return;
    });
  enableDevTools([client]);
  return client;
};

const login = async (panAccessToken: string): Promise<memberPb.LoginRes> => {
  const req: memberPb.LoginReq = new memberPb.LoginReq();
  let res: memberPb.LoginRes;
  req.setAccessToken(panAccessToken);
  try {
    res = await service().login(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const refreshGinToken = async (
  ginToken: string
): Promise<memberPb.ValidateTokenRes> => {
  const req: memberPb.ValidateTokenReq = new memberPb.ValidateTokenReq();
  let res: memberPb.ValidateTokenRes;
  req.setGinToken(ginToken);
  try {
    res = await service().validateToken(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getMe = async (ginToken: string): Promise<memberPb.MeRes> => {
  const req: memberPb.MeReq = new memberPb.MeReq();
  let res: memberPb.MeRes;
  req.setGinToken(ginToken);
  try {
    res = await service().me(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const generateAvatarUploadUrl = async (
  ginToken: string
): Promise<memberPb.UploadAvatarRes> => {
  const req: memberPb.UploadAvatarReq = new memberPb.UploadAvatarReq();
  let res: memberPb.UploadAvatarRes;
  req.setGinToken(ginToken);
  try {
    res = await service().uploadAvatar(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const updateMemberInfo = async (
  payload: { email: string },
  ginToken: string
): Promise<memberPb.UpdateMeRes> => {
  const req: memberPb.UpdateMeReq = new memberPb.UpdateMeReq();
  let res: memberPb.UpdateMeRes;
  req.setGinToken(ginToken);
  req.setEmail(payload.email);
  try {
    res = await service().updateMe(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getMyCompany = async (
  ginToken: string
): Promise<memberPb.MyCompanyRes> => {
  const req: memberPb.MyCompanyReq = new memberPb.MyCompanyReq();
  let res: memberPb.MyCompanyRes;
  req.setGinToken(ginToken);
  try {
    res = await service().myCompany(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

export const apiMember = {
  login,
  refreshGinToken,
  getMe,
  generateAvatarUploadUrl,
  updateMemberInfo,
  getMyCompany,
};
