import { apiCompany } from "@/lib/api_gin";
import { apiStar } from "@/lib/api_star";
import { apiMember } from "@/lib/api_member";
import { UploadFileType } from "@/models/gin";
import { uploadFile } from "@/lib/upload";
import { ref } from "vue";
const defaultAvatarImageUrl = ref(
  "https://www.mymyuc.net/images/noavatar_middle.gif"
);
const avatarHintImageUrl = ref(
  "https://public.hare200.com/gin/icon_upload_2.png"
);
const logoSamples = {
  "JKF LOGO": "https://public.hare200.com/gin/JKF_LOGO.png",
  示範圖一: "https://public.hare200.com/gin/image_example_1.png",
  示範圖二: "https://public.hare200.com/gin/image_example_2.png",
  示範圖三: "https://public.hare200.com/gin/image_example_3.png",
};
interface UploadedImage {
  path: string;
  url: string;
}

const uploadFileByStaff = async (
  file: File,
  type: UploadFileType,
  starObjectId: string,
  ginToken: string
): Promise<UploadedImage> => {
  const res = await apiCompany.upload(
    {
      fileName: file.name,
      type: type,
      starObjectId: starObjectId,
    },
    ginToken
  );
  await uploadFile(res, file, () => {
    return;
  });
  return {
    path: res.getDisplayImagePath(),
    url: res.getPublicUrl(),
  };
};

const uploadFileByStar = async (
  file: File,
  type: UploadFileType,
  accessToken: string
): Promise<UploadedImage> => {
  const res = await apiStar.uploadFile(
    {
      fileName: file.name,
      type: type,
    },
    accessToken
  );
  await uploadFile(res, file, () => {
    return;
  });
  return {
    path: res.getDisplayImagePath(),
    url: res.getPublicUrl(),
  };
};

const uploadAvatarByStaff = async (
  payload: {
    file: File;
    objectId: string;
  },
  ginToken: string
): Promise<UploadedImage> => {
  const res = await apiCompany.uploadAvatar(payload.objectId, ginToken);
  await uploadFile(res, payload.file, async () => {
    return;
  });
  return {
    path: res.getDisplayImagePath(),
    url: res.getUrlToUpload() + res.getDisplayImagePath(),
  };
};

const uploadAvatarBySelf = async (
  payload: { file: File },
  accessToken: string
): Promise<UploadedImage> => {
  const res = await apiStar.uploadAvatar(accessToken);
  await uploadFile(res, payload.file, async () => {
    return;
  });
  return {
    path: res.getDisplayImagePath(),
    url: res.getUrlToUpload() + res.getDisplayImagePath(),
  };
};

export const imageStore = {
  uploadFileByStaff,
  uploadFileByStar,
  uploadAvatarByStaff,
  uploadAvatarBySelf,
  avatarHintImageUrl,
  defaultAvatarImageUrl,
  logoSamples,
};
