import dayjs, { Dayjs } from "dayjs";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import moment, { Moment } from "moment";

function timestampToMoment(time: Timestamp | undefined): Moment | undefined {
  if (time) {
    return moment.unix(time.getSeconds());
  } else {
    return undefined;
  }
}
function timestampToDayjs(time: Timestamp | undefined): Dayjs | undefined {
  if (time) {
    return dayjs.unix(time.getSeconds());
  } else {
    return undefined;
  }
}

function momentToTimestamp(time: Moment | undefined): Timestamp | undefined {
  if (time) {
    return new Timestamp().setSeconds(time.unix());
  } else {
    return undefined;
  }
}
function dayjsToTimestamp(time: Dayjs | undefined): Timestamp | undefined {
  if (time) {
    return new Timestamp().setSeconds(time.unix());
  } else {
    return undefined;
  }
}

function strictTimestampToMoment(time: Timestamp): Moment {
  return moment.unix(time.getSeconds());
}
function strictTimestampToDayjs(time: Timestamp): Dayjs {
  return dayjs.unix(time.getSeconds());
}

function strictMomentToTimestamp(time: Moment): Timestamp {
  return new Timestamp().setSeconds(time.unix());
}
function strictDayjsToTimestamp(time: Dayjs): Timestamp {
  return new Timestamp().setSeconds(time.unix());
}

const parseJwt = (token: string): { [key: string]: any } => {
  if (!token) {
    return {};
  }
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    return {};
  }
};

function fallbackCopyTextToClipboard(text: string): void {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text: string): void {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

const deviceBoundary = 480;
const isMobile = (): boolean => {
  return window.outerWidth <= deviceBoundary;
};
const isDesktop = (): boolean => {
  console.log(
    "isDesktop: ",
    window.outerWidth,
    deviceBoundary,
    window.outerWidth > deviceBoundary
  );

  return window.outerWidth > deviceBoundary;
};

export {
  timestampToMoment,
  timestampToDayjs,
  momentToTimestamp,
  dayjsToTimestamp,
  strictMomentToTimestamp,
  strictDayjsToTimestamp,
  strictTimestampToMoment,
  strictTimestampToDayjs,
  parseJwt,
  copyTextToClipboard,
  isMobile,
  isDesktop,
};
