import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_left_outlined = _resolveComponent("left-outlined")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(_component_a_button, {
    class: "goback_btn",
    onClick: _ctx.goback
  }, {
    default: _withCtx(() => [
      _createVNode(_component_left_outlined),
      _createTextVNode(" 返回列表 ")
    ]),
    _: 1
  }, 8, ["onClick"]))
}