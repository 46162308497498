
import { defineComponent, onMounted, provide, ref, Ref } from "vue";
import zhTW from "ant-design-vue/es/locale/zh_TW";
export default defineComponent({
  name: "App",
  components: {},
  setup() {
    return { zhTW };
  },
});
