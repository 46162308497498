
import {
  defineComponent,
  reactive,
  onMounted,
  toRefs,
  Ref,
  ref,
  onBeforeUnmount,
  computed,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import StaffCard from "@/components/StaffCard.vue";
import { apiCompany } from "@/lib/api_gin";
import { userStore } from "@/store/user";
import {
  SearchOutlined,
  DownOutlined,
  UpOutlined,
  EyeFilled,
} from "@ant-design/icons-vue";
import CommonCard from "@/components/CommonCard.vue";
import { GinUser } from "@/models/gin";
import GoBackButton from "@/components/GoBackButton.vue";
const searchScopes = {
  藝人名稱: "name",
  UID: "uid",
  手機號碼: "phone",
};
export default defineComponent({
  name: "ViewStaffDetail",
  components: {
    StaffCard,
    SearchOutlined,
    CommonCard,
    DownOutlined,
    UpOutlined,
    GoBackButton,
    EyeFilled,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      searchScope: "name",
      search: "",
      isReady: false,
      edit: Boolean(route.query.edit),
      page: 1,
      limit: 10,
      total: 0,
      isStickingTop: true,
      isVisible: false,
      name: "",
      avatar: "",
    });
    const ginId = Number(route.params.ginId);
    const currentStaff: Ref<GinUser | null> = ref(null);
    const getStaffDetail = async () => {
      state.isReady = false;
      const res = await apiCompany.getStaffDetail(
        { ginId: ginId },
        userStore.ginToken.value
      );
      const memberInfo = res.getData();
      if (memberInfo) {
        state.name = memberInfo.getDisplayName();
        state.avatar = memberInfo.getAvatarUrl();
        currentStaff.value = new GinUser(memberInfo);
      }
      state.isReady = true;
    };
    const stars: Ref<GinUser[]> = ref([]);
    const getStarList = async (page: number) => {
      state.page = page;
      let searchScopeParams: {
        name: string | undefined;
        uid: number | undefined;
        phone: string | undefined;
      } = {
        name: undefined,
        uid: undefined,
        phone: undefined,
      };
      if (state.searchScope === "name") {
        searchScopeParams.name = state.search;
      } else if (state.searchScope === "uid") {
        searchScopeParams.uid = Number(state.search);
      } else if (state.searchScope === "phone") {
        searchScopeParams.phone = state.search;
      }
      const res = await apiCompany.getStarList(
        {
          staffGinId: ginId,
          page: page,
          limit: state.limit,
          status: true,
          isMine: false, // 無意義參數，會被staffGinId取代
          ...searchScopeParams,
        },
        userStore.ginToken.value
      );
      stars.value = res.getDataList().map((e) => new GinUser(e));
      state.total = res.getTotal();
    };

    const checkIsStickingTop = () => {
      if (document.documentElement.scrollTop > 0) {
        state.isStickingTop = false;
        document.removeEventListener("scroll", checkIsStickingTop);
      }
    };
    const isStatusEditable = computed(() => {
      if (userStore.currentUser.value.role.isCompany()) {
        return true;
      } else if (
        userStore.currentUser.value.role.isAdmin() &&
        currentStaff.value?.role === "agent"
      ) {
        return true;
      } else {
        return false;
      }
    });
    onMounted(async () => {
      await getStaffDetail();
      await getStarList(1);
      document.addEventListener("scroll", checkIsStickingTop);
    });
    onBeforeUnmount(() => {
      document.removeEventListener("scroll", checkIsStickingTop);
    });
    return {
      ginId,
      ...toRefs(state),
      stars,
      getStarList,
      currentStaff,
      isStatusEditable,
      searchScopes,
    };
  },
});
