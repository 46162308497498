import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import AdminList from "@/views/AdminList.vue";
import AgentList from "@/views/AgentList.vue";
import StarList from "@/views/StarList.vue";
import RegsiterList from "@/views/RegisterList.vue";
import RegisterDetail from "@/views/RegisterDetail.vue";
import Income from "@/views/Income.vue";
import Rank from "@/views/Rank.vue";
import StaffDetail from "@/views/StaffDetail.vue";
import StarDetail from "@/views/StarDetail.vue";
import Login from "@/views/public/Login.vue";
import Register from "@/views/public/Register.vue";

import LayoutGin from "@/layouts/gin.vue";
import { userStore } from "@/store/user";
import { clientStore } from "@/store/client";
const isLogin = userStore.isLogin;
const isClientLogin = clientStore.isLogin;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "登入 -  Star經紀管理系統",
    },
    beforeEnter(to, from, next) {
      if (isLogin.value) {
        next({ name: "home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/new/:code",
    name: "Register",
    component: Register,
    meta: {
      title: "註冊",
    },
  },
  {
    path: "/checkin/:postId",
    name: "PublicPostCheckIn",
    component: () => import("@/views/public/PostCheckIn.vue"),
    meta: {
      title: "通告報到 - Star經紀管理系統",
      name: "通告報到",
    },
  },
  {
    path: "/client",
    meta: {
      isClientScope: true,
    },
    children: [
      {
        path: "income",
        name: "ClientIncomeOverview",
        component: () => import("@/views/client/IncomeOverview.vue"),
        meta: {
          title: "收入總覽 - JKF",
          name: "收入總覽",
          requiresClientAuth: true,
        },
      },
      {
        path: "login",
        name: "ClientLogin",
        component: () => import("@/views/client/Login.vue"),
        meta: {
          title: "登入 - JKF",
          name: "登入",
        },
        beforeEnter(to, from, next) {
          if (isClientLogin.value) {
            next({ name: "ClientIncomeOverview" });
          } else {
            next();
          }
        },
      },
    ],
  },

  {
    path: "/",
    name: "root",
    component: LayoutGin,
    meta: { requiresAuth: true },
    children: [
      {
        path: "home",
        name: "home",
        component: Home,
        meta: {
          title: "首頁 - Star經紀管理系統",
        },
      },
      {
        path: "admins",
        name: "adminCRUD",
        children: [
          {
            path: "",
            name: "admins",
            component: AdminList,
            meta: {
              title: "管理員列表 - Star經紀管理系統",
            },
          },
          {
            path: "detail/:ginId",
            name: "adminDetail",
            component: StaffDetail,
            meta: {
              title: "管理員詳細 - Star經紀管理系統",
            },
          },
        ],
      },
      {
        path: "agents",
        name: "agentCRUD",
        children: [
          {
            path: "",
            name: "agents",
            component: AgentList,
            meta: {
              title: "經紀列表 - Star經紀管理系統",
            },
          },
          {
            path: "detail/:ginId",
            name: "agentDetail",
            component: StaffDetail,
            meta: {
              title: "經紀詳細 - Star經紀管理系統",
            },
          },
        ],
      },
      {
        path: "stars",
        name: "starCRUD",
        children: [
          {
            path: "",
            name: "stars",
            component: StarList,
            meta: {
              title: "藝人列表 - Star經紀管理系統",
            },
          },
          {
            path: "detail/:uid",
            name: "starDetail",
            component: StarDetail,
            meta: {
              title: "藝人詳細 - Star經紀管理系統",
            },
          },
        ],
      },
      {
        path: "registers",
        name: "registerCRUD",
        children: [
          {
            path: "",
            name: "registers",
            component: RegsiterList,
            meta: {
              title: "待審核資料 - Star經紀管理系統",
            },
          },
          {
            path: "detail/:registerId",
            name: "registerDetail",
            component: RegisterDetail,
            meta: {
              title: "審核資料 - Star經紀管理系統",
            },
          },
        ],
      },
      {
        path: "/income",
        name: "income",
        component: Income,
        meta: {
          title: "總帳資料 - Star經紀管理系統",
        },
      },
      {
        path: "rank",
        children: [
          {
            path: ":scope(agent)",
            name: "agentRank",
            component: Rank,
            meta: {
              title: "經紀排行榜 - Star經紀管理系統",
            },
          },
          {
            path: ":scope(star)",
            name: "starRank",
            component: Rank,
            meta: {
              title: "藝人排行榜 - Star經紀管理系統",
            },
          },
        ],
      },
      {
        path: "posts",
        name: "postCRUD",
        children: [
          {
            path: "",
            name: "posts",
            component: () => import("@/views/PostList.vue"),
            meta: {
              title: "通告列表 - Star經紀管理系統",
              name: "通告列表",
            },
          },
          {
            path: "new",
            name: "postNew",
            component: () => import("@/views/PostNew.vue"),
            meta: {
              title: "建立通告 - Star經紀管理系統",
            },
          },
          {
            path: "detail/:id",
            name: "postDetail",
            component: () => import("@/views/PostDetail.vue"),
            meta: {
              title: "通告內容 - Star經紀管理系統",
            },
          },
          {
            path: "checkin/:id",
            name: "postCheckIn",
            component: () => import("@/views/PostCheckIn.vue"),
            meta: {
              title: "通告報到 - Star經紀管理系統",
              name: "通告報到",
            },
          },
          {
            path: "settle/:id",
            name: "postSettle",
            component: () => import("@/views/PostSettle.vue"),
            meta: {
              title: "通告結算 - Star經紀管理系統",
              name: "通告結算",
            },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const title = to.meta?.title;
  if (typeof title === "string") {
    window.document.title = title;
  } else {
    window.document.title = "Star經紀管理系統";
  }
  if (to.meta.isClientScope) {
    await clientStore.initUser();
  }

  if (to.meta.requiresAuth) {
    if (!isLogin.value) {
      await userStore.initUser();
    }
    if (!isLogin.value) {
      next({ name: "login" });
    } else {
      next();
    }
  } else if (to.meta.requiresClientAuth) {
    if (!isClientLogin.value) {
      next({ name: "ClientLogin" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
